import React from 'react';
import './About.css';
import { Helmet } from 'react-helmet';
import { useSpring, animated } from 'react-spring';

const About = () => {
  const props = useSpring({
    from: { opacity: 0, transform: 'scale(0.8)' },
    to: { opacity: 1, transform: 'scale(1)' },
  });
  
  return (
    <>
    <animated.div style={props}>
    <Helmet>
  <title>About Dmyor</title>
  <meta name="description" content="Learn more about Dmyor - the platform that helps you document what is yet to be done written by you!" />
  <meta property="og:title" content="Dmyor is a platform where dreams and reality collide as one's 'want to' do converts to 'done'." />
  <meta property="og:type" content="website" />
  <meta property="og:description" content="Learn more about Dmyor - the platform that helps you document what is yet to be done written by you!" />
  <meta property="og:image" content="https://res.cloudinary.com/dkgg6mdtb/image/upload/v1695966172/android-icon-144x144_ildr9u.png" /> 
  <meta property="og:url" content="https://dmyor.com/about" />
</Helmet>
    <div className="about">
        <p></p>
        <p>
        Welcome to Dmyor, a platform where your journey to what you want can be stored. It can be a goal, a wish, a message to your future self, a checklist, a discipline or anything you can imagine. Our goal is to provide a platform for storing what is yet to happen written by you. Everything you write with your imagination and determination, we store your journey as you make it a reality.
        </p>
<h2 className = "para-1">Create Node</h2>
<p>
Start by making a node. Think of your Node as your personal time capsule – In each node, you can store thoughts, goals, vision or journey that you would want to release one day. When you go forward, you can add updates describing your progress.
</p>
<h2 className = "para-1">A World of Anonymity</h2>
<p>
The nodes you write are available in dmyor explore. You can also see what nodes other users are making. The nodes are anonymous. Users can see the node itself but can not see who created them. You can remove a node from explore page by changing its setting.
</p>
<h2 className = "para-1">Release</h2>
<p>
Every node features a 'Release' button. When you're ready, with a single click, the node changes its status. The node now changes its status from active to released indicating the journney is over. A released node is no longer anonymous. After being released creator's name is shown with the node. 
</p>
<h2 className = "para-1">Share</h2>
<p>
 You can share your node before and after its released. The only difference being that an active node is anonymous. You also have access to visibility controls through which you have control of sharing your node.
</p>
<p>

You are the one who knows what you want to do. So start making nodes for whatever you want to do someday. Let them float anonymously in dmyor till the day you release and claim them. We aspire to become a platform where dreams and reality collide as one's <span>want to do</span> converts to <span>done</span>.

</p>


    </div>
    </animated.div>
    </>
  )
}

export default About