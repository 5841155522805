const formatDate = (date) => {
    const day = date.getDate();
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const month = monthNames[date.getMonth()];
    // const year = date.getFullYear().toString().slice(-2);
    const year = date.getFullYear().toString();
    return `${day} ${month} ${year}`;
  }

export default formatDate;