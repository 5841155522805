import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, loginWithGoogle, register } from '../actions/userAction';
import './Signup.css';
import { Helmet } from 'react-helmet';
import countries from 'i18n-iso-countries';

const Signup = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );

  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [btnloading, setbtnloading] = useState(false);

  const validateName = (value) => {
    if (!value) {
      return 'Name is required';
    }
    return '';
  };

  const validateDateOfBirth = (value) => {
    if (!value) {
      return 'Date of Birth is required';
    }
    return '';
  };

  const validateEmail = (value) => {
    if (!value) {
      return 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      return 'Invalid email address';
    }
    return '';
  };

  const validatePassword = (value) => {
    if (!value) {
      return 'Password is required';
    } else if (value.length < 8) {
      return 'Password must be at least 8 characters long';
    }
    return '';
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    setErrors((prevErrors) => ({ ...prevErrors, name: validateName(value) }));
  };

  const handleDobChange = (e) => {
    const value = e.target.value;
    setDob(value);
    setErrors((prevErrors) => ({ ...prevErrors, dob: validateDateOfBirth(value) }));
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setErrors((prevErrors) => ({ ...prevErrors, email: validateEmail(value) }));
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setErrors((prevErrors) => ({ ...prevErrors, password: validatePassword(value) }));
  };

  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    const countryCodes = countries.getNames('en');
    const countryArray = Object.entries(countryCodes).map(([code, name]) => ({
      code,
      name,
    }));
    setCountryList([{ code: '', name: 'country' }, ...countryArray]);
  }, []);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const registerSubmit = async (e) => {
    e.preventDefault();

    const nameError = validateName(name);
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);

    if (!nameError && !emailError && !passwordError && selectedCountry !== '') {
      const data = { name, email, password, country: selectedCountry };
      try {
        setbtnloading(true);
        await dispatch(register(data));
      } catch (error) {
        // Handle error
      } finally {
        setbtnloading(false);
      }
    } else {
      setErrors({
        name: nameError,
        email: emailError,
        password: passwordError,
        country: selectedCountry === '' ? 'Please select a country' : '',
      });
    }
  };

  const redi = location.search ? location.search.split("=")[1] : "/";
  useEffect(() => {
    if (error) {
      // console.log("Error ", error);
      dispatch(clearErrors());
    }

    if (isAuthenticated) {
      // console.log("redirecting");
      // console.log(redi);
      history(redi);
    }
  }, [dispatch, error, alert, history, isAuthenticated, redi]);

  const handleGoogleAuth = () => {
    // console.log("Google login initiated");
    dispatch(loginWithGoogle());
  };

  const countryOptions = countryList.map((country, index) => (
    <option key={index} value={country.code}>
      {country.name}
    </option>
  ));

  return (
    <div className="row signup-container">
      <Helmet>
        <title>Sign Up for Dmyor - Document your journey of what you </title>
        <meta name="description" content="Join Dmyor and start ducumenting your journeys, add updates as you progress, release and share as your journey comes to end." />
        <meta property="og:title" content="Sign Up for Dmyor - Document your journey and Bend Reality" />
        <meta property="og:description" content="Join Dmyor and start ducumenting your journeys, add updates as you progress, release and share as your journey comes to end." />
        <meta property="og:image" content="/path/to/signup-og-image.jpg" />
        <meta property="og:url" content="https://res.cloudinary.com/dkgg6mdtb/image/upload/v1695966172/android-icon-144x144_ildr9u.png" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="col-lg-6 empty-space">
        <h1 className="signupquote">Work.</h1>
        <h1 className="signupquote">Achieve.</h1>
        <h1 className="signupquote">Release.</h1>
      </div>
      <div className="col-lg-6 signup-maincontainer">
        <div className="form-container">
          <h2>Create an <span className='accountSpan'>account</span></h2>
          <form onSubmit={registerSubmit}>
            <div className={`form-group ${errors.name ? 'error' : ''}`}>
              <input
                type="text"
                id="name"
                className={`${errors.email ? '' : 'add-margin'}`}
                value={name}
                placeholder='name'
                onChange={handleNameChange}
                required
              />
              {errors.name ? (<p className="signup-error-text">{errors.name}</p>) : (<p></p>)}
            </div>
            <div className={`form-group ${errors.email ? 'error' : ''}`}>
              <input
                type="email"
                id="email"
                className={`${errors.email ? '' : 'add-margin'}`}
                value={email}
                placeholder='email'
                onChange={handleEmailChange}
                required
              />
              {errors.email ? (<p className="signup-error-text">{errors.email}</p>) : (<p></p>)}
            </div>
            <div className="form-group">
              <select
                id="country"
                className="add-margin"
                value={selectedCountry}
                onChange={handleCountryChange}
                required
              >
                {countryOptions}
              </select>
              {errors.country ? (<p className="signup-error-text">{errors.country}</p>) : (<p></p>)}
            </div>
            <div className={`form-group ${errors.password ? 'error' : ''}`}>
              <input
                type='password'
                id="password"
                className={`${errors.email ? '' : 'add-margin'}`}
                value={password}
                placeholder='password'
                onChange={handlePasswordChange}
                required
              />
              {errors.password ? (<p className="signup-error-text">{errors.password}</p>) : (<p></p>)}
            </div>

            {btnloading ? (
              <button className="signup-btn btn-loading"></button>
            ) : (
              <button className='btn signup-btn' type="submit">Sign Up</button>
            )}
          </form>
          <div className="divider">
            <hr className="signup-divider-line" />
            <span className="divider-text">OR</span>
            <hr className="signup-divider-line" />
          </div>
          <button className='btn popup-btn-signup' onClick={handleGoogleAuth}><i className='bx bxl-google'></i>Sign up with Google</button>
          <p>by signing up, you agree to Dmyor <Link to="/terms">terms of service</Link> and <Link to = "/privacy-policy">privacy policy</Link>.</p>
          <p className='createnew'>Already have an account? <Link to="/login"><span>Login</span></Link></p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
