import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import "./Reset.css";
import config from "../../config";
const serverurl = config.apiUrl;
const serverConfig = {
    withCredentials:true,
}

const Reset = () => {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [btnloading, setbtnloading] = useState(false);

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(enteredEmail);
    // console.log(email, isValid);
    setIsValidEmail(isValid);
  };
  const handleResetPassword = async () => {
    const data = {
      email: email,
    }
    try{
      setbtnloading(true);
    const res = await axios.post(`${serverurl}/api/v1/forgotpassword`, data);
    setIsEmailSent(true);
    }catch(error){
      toast.error(error.response.data.message);
    }finally{
      setbtnloading(false);
    }
  };

  return (
    <div className="card-container">
      <div className="reset-card">
        <h2>Forgot Password</h2>
        {isEmailSent ? (
          <>
          <p>Email Sent. Please find password reset instructions on your registered email address.</p>
          </>
        ) : (
          <form>
            <div className="reset-form">
                <p>Enter your email and click on send button. We will send password reset link to your email.</p>
              <label>Email:</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={handleEmailChange}
              />
            {!isValidEmail && <p className="password-reset-alert">Please enter a valid email.</p>}  
            </div>
            {btnloading?(
            <button className= "pay-btn btn-loading" type="button">
            </button>
          ):(
            <button 
            type="button"
            className= "pay-btn btn-reset"
            onClick={handleResetPassword} 
            >
            Reset password
            </button>
          )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Reset;
