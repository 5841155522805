import React, { useEffect, useState } from 'react'
import formatDate from '../../util';
const UpdateMessage = ({note, date}) => {
    const [formatteddate, setformatteddate] = useState();
    useEffect(() => {
        // setformatteddate(moment(date).format('DD-MM-yyyy'));
        const thisdate = new Date(date);
        const formattedDate = formatDate(thisdate);
        setformatteddate(formattedDate);
    }, [])
    
  return (
    <div>
        <div className="desc">
            {
              note.split('\n').map((line, index) => (
                <p key={index}>
                  {line}
                  <br />
                </p>
              ))
            }
            </div>
            <div className="status-bar">
              <p>{formatteddate}</p>
            </div>
    </div>
  )
}

export default UpdateMessage