import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../layout/Loder';
import moment from 'moment';
import TokenPopup from './TokenPopup';
import './Account.css'; // Import the CSS file for styling
import { logout } from '../../actions/userAction';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Account = () => {
  const { user, loading, isAuthenticated } = useSelector((state) => state.user);
  const {totalPosts} = useSelector((state) => state.posts);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dob, setdob] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [tokenCount, setTokenCount] = useState(5);
  const [logoutloading, setlogoutloading] = useState(false);
  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
    if(user.dob)setdob( moment(user.dob).format('DD-MM-yyyy'));
  }, [window.location, isAuthenticated,dob]);

  const closePopup = () => {
    setShowPopup(false);
  };

  const updateTokenCount = (newCount) => {
    setTokenCount(newCount);
  };

  const handlelogout = async(e) => {
    e.preventDefault();
    try{
    setlogoutloading(true);
    await dispatch(logout());
    }catch(error){
      toast.error(error);
    }finally{
      setlogoutloading(false);
    }
  }

  return (
    <>
    <Helmet>
        <title>Account Details</title>
      </Helmet>
    {loading ? (
      <Loader/>
    ) : (
      <div className="account-page">
        <h2>Account Details</h2>
        <div className="details">
          <div className="row account-item">
            <p className="col-4 col-lg-4 account-item-headings">Name</p>
            <p className="col-8 col-lg-8">{user.name}</p>
          </div>
          <div className="row account-item">
            <p className="col-4 col-lg-4 account-item-headings">email</p>
            <p className="col-6 col-lg-8">{user.email}</p>
            
          </div>
          <div className="row account-item">
            <p className="col-4 col-lg-4 account-item-headings">D-credits</p>
            <p className="col-6 col-lg-6">{user.tokens}</p>
            <div className="col-2 col-lg-2">
              <button className="btn btn-edit email" onClick={() => setShowPopup(!showPopup)}>Add</button>
            </div>
          </div>
          <div className="row account-item">
            <p className="col-4 col-lg-4 account-item-headings">Total nodes</p>
            <p className="col-8 col-lg-8">{totalPosts}</p>
          </div>
          {/* <div className="payment-details">
            <button className="btn btn-logout" onClick={handlelogout}><i class='bx bx-log-out'></i> {logoutloading?'Logging out...':'Logout'}</button>
          </div> */}
          <div className="logout">
            <button className="btn btn-logout" onClick={handlelogout}><i class='bx bx-log-out'></i> {logoutloading?'Logging out...':'Logout'}</button>
            {/* <Link to="/dashboard/payment-history">
            <button className="btn btn-logout"> Payment history</button>
            </Link> */}
          </div>
        </div>
      {showPopup && (
            <TokenPopup
              tokenCount={tokenCount}
              updateTokenCount={updateTokenCount}
              closePopup={closePopup}
            />
          )}
    </div>
    )}
    </> 
  );
};

export default Account;
