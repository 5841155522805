import React, {useEffect, useState} from 'react';
import './Settingspopup.css'
import { useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import { useDispatch } from 'react-redux';
import { deletePost, changeVisibility } from '../../actions/postAction';
import { toast } from 'react-toastify';
import Loader from '../../layout/Loder';
import Dropdown from './Dropdown';
import DeletePopup from './DeletePopup';

const Settingspopup = ({closeSettings, post, setpost }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setchecked] = useState(post.visibility === "true");
  const [loading, setloading] = useState(true);
  const [btnloading, setbtnloading] = useState(false);
  const [savebtnloading, setsavebtnloading] = useState(false);
  const [Status, setStauts] = useState(post.status);
  const [selectedOption, setselectedOption] = useState(post.status == "standby"? { value: 'standby', label: 'Standby', color: 'blue', background:'rgb(255, 255, 133)' }:(post.status==="active")?{ value: 'active', label: 'Active', color: 'green',background:'rgb(170, 255, 170)' }: { value: 'released', label: 'Released', color: 'rgb(255, 123, 0)',background:'rgb(170, 255, 170)' });
  const [deletepopup, setdeletepopup] = useState(false);
  const handleChange = (e) => {
    setchecked(!checked);
  }
const options = [
  { value: 'standby', label: 'Standby', color: 'blue', background:'rgb(255, 255, 133)' },
  { value: 'active', label: 'Active', color: 'green',background:'rgb(170, 255, 170)' },
  { value: 'released', label: 'Released', color: 'rgb(255, 123, 0)',background:'rgb(170, 255, 170)' },
];
//Delete handler
const beginDelete = async() => {
  setdeletepopup(!deletepopup);
  // handleDelete();
}
const handleDelete = async() => {
  try{
    const data = {
      postId:post._id,
    }
    setbtnloading(true);
    const res = await dispatch(deletePost(data));
    if(res.success){
      toast.success("Node deleted");
      navigate("/mynotes");
    }else{
      toast.error(res.message);
    }
  }catch(error){
    toast.error(error);
  }finally{
    setbtnloading(false);
  }
}

const saveChanges = async() => {
  try{
    const data = {
      postId: post._id,
      visibility: checked ,
      status: Status,
    };
    setsavebtnloading(true);
    const res = await dispatch(changeVisibility(data));
    if(res && res.status == 409){
      toast.error(res.data.message);
    }else{
      const updatedPost = { ...post };
      updatedPost.visibility = String(checked);
      updatedPost.status = String(Status);
      // console.log(updatedPost);
      setpost(updatedPost);
      toast.success("Saved");
    }
  }catch(error){
    toast.error(error);
  }finally{
    setsavebtnloading(false);
  }
}
  useEffect(() => {
    // console.log(post.visibility === "true");
    setchecked(post.visibility === "true");
  }, []);
  useEffect(() => {
    setloading(false);
  }, [checked]);

  

  return (
    <>
    <div className="blur-background"></div>
    <i class='bx bx-x' onClick={(e) => closeSettings(e)}></i>

    <div className="popup">
      {loading? (
        <Loader/>
      ):(
        <>
        <h2>Settings</h2>
      <label htmlFor="material-switch">
          <div className="private-detail">
          <span>Visibility</span>
          <p>(Switching off visibility will remove your node from Dmyor search)</p>
          </div>
          <Switch
            checked={checked}
            onChange={handleChange}
            onColor="#F9861B"
            onHandleColor="#F9861B"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgb(255, 123, 0)"
            activeBoxShadow="0px 0px 1px 10px rgba(255, 123, 0, 0.2)"
            height={15}
            width={35}
            className="react-switch"
            id="material-switch"
          />
    </label>
    {post.statis!=="released" && 
    <div className="private-detail status">
          <span>Status</span>
          <Dropdown id="status" setStatus={setStauts} selectedOption={selectedOption} setselectedOption={setselectedOption} />
    </div>
    }
    <div className="btn-save">
      {
        savebtnloading?(
          <button disabled={true} className="btn btn-orange">Saving...</button>
        ):(
          <button  className="btn btn-orange" onClick={saveChanges}>Save Changes</button>
        )
      }
    
    </div>
    <div className="delete">
    <p>Please note that the node would be permanently deleted.</p>
    {btnloading?(
            <button disabled={true} className= "btn btn-delete">
              Deleting...
            </button>
          ):(
            <button className='btn btn-delete' onClick={beginDelete}>Delete</button>
          )}
    </div>
        </>
      )}
      
    
    {/* <button className='btn btn-close' onClick={closeSettings}>close</button> */}
    </div>
    {
      deletepopup && <DeletePopup handleDelete={handleDelete} setdeletepopup={setdeletepopup}/>
    }
    </>
  );
};

export default Settingspopup;
