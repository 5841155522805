import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './Checkout.css';
import { checkoutRequest, getPrice, phonepeCheckoutRequest, razorpayCheckoutRequest } from '../../actions/paymentAction';
import Loader from '../../layout/Loder';
import {toast} from 'react-toastify';

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state)=> state.user);
  const dispatch = useDispatch();
  const [totalTokens, setTotalTokens] = useState(1);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [price, setprice] = useState();
  const [loading, setloading] = useState(true);
  const [btnloading, setbtnloading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState('razorpay');
  const [expanded, setExpanded] = useState(true);

  const handlePaymentMethodChange = (event) => {
    const method = event.target.value;
    setSelectedMethod(method);
    setExpanded(true);
  };
  const fetchPrice = async() => {
    try{
      // console.log("Fetching price");
    const fetchprice = await dispatch(getPrice());
    // console.log(fetchprice);
    if (fetchprice !== undefined && fetchprice !== null) {
      // console.log(fetchprice, "yoyo");
      setprice(fetchprice);
      setloading(false);
    } else {
      navigate("/error");
    }
    }catch(error){
      toast.error(error);
    }
  }
  useEffect(() => {
    // navigate("/building");
    const searchParams = new URLSearchParams(location.search);
    const tokenValue = parseInt(searchParams.get('tokens')) || 1;
    // console.log(tokenValue);
    if(tokenValue%5 === 0){
    setTotalTokens(tokenValue);
    }else{
      setTotalTokens(Math.floor(tokenValue/5)*5+5);
    }
    fetchPrice()
  }, [location]);

  const handlePayment = async() => {
    setbtnloading(true);
    // console.log(btnloading);
    try{
      const order = {
        quantity: totalTokens, 
        userId: user._id,
        name:user.name,
      };
      if(selectedMethod === 'razorpay'){
        await dispatch(razorpayCheckoutRequest(order, user));
      }
      else if(selectedMethod === 'stripe'){
        await dispatch(checkoutRequest(order, user));
        setPaymentSuccess(true);
      }else{
        order.transactionId="MT7850590068188104";
        await dispatch(phonepeCheckoutRequest(order, user))
      }
    }catch(error){
      toast.error(error);
    }finally{
      setbtnloading(false);
    }
  };
    
  
  return (
    <>
    {loading? (
      <Loader/>
    ):(
    <div className="checkout-container row">
          <div className="payment-methods col-lg-8">
              <h1>DMYOR</h1>
              <table className="user-table">
              <tbody>
                <tr>
                  <td><strong>Name:</strong></td>
                  <td>{user.name}</td>
                </tr>
                <tr>
                  <td><strong>Email:</strong></td>
                  <td>{user.email}</td>
                </tr>
              </tbody>
            </table>
            <h3>Payment</h3>
            <p>All payments are secured and encrypted.</p>
            <div className="payment-options">
            <div className='option'>
              <div className="radio-container">
              <input
                type="radio"
                value="razorpay"
                checked={selectedMethod === 'razorpay'}
                onChange={handlePaymentMethodChange}
              />
              <label htmlFor="razorpay">Razorpay (UPI/Cards)</label>
              </div>
              {expanded && selectedMethod === 'razorpay' && (
              <div className='payment-info-container'>
              <p className="payment-info">After clicking “Complete Order”, you will be redirected to Razorpay (Cards, UPI, NetBanking, Wallets) to complete your purchase securely.</p>
              </div>
              )}
            </div>
            {/* <div className='option'>
              <div className="radio-container">
              <input
                type="radio"
                value="phonepe"
                checked={selectedMethod === 'phonepe'}
                onChange={handlePaymentMethodChange}
              />
              <label htmlFor="razorpay">Phonepe (UPI/Cards)</label>
              </div>
              {expanded && selectedMethod === 'phonepe' && (
              <div className='payment-info-container'>
              <p className="payment-info">After clicking “Complete Order”, you will be redirected to Razorpay (Cards, UPI, NetBanking, Wallets) to complete your purchase securely.</p>
              </div>
              )}
            </div>
            <div className='option'>
              <div className="radio-container">
              <input
                type="radio"
                value="stripe"
                checked={selectedMethod === 'stripe'}
                onChange={handlePaymentMethodChange}
              />
              <label htmlFor="stripe">Stripe</label>
              </div>
              {expanded && selectedMethod === 'stripe' && (
              <div className='payment-info-container'>
              <p className="payment-info">After clicking “Complete Order”, you will be redirected to Stripe (Cards and Wallets) to complete your purchase securely.</p>
              </div>
            )}
            </div> */}
            </div>
        </div>
        <div className="checkout-form col-lg-4">
          <div className="price-line">
          <p>Total D-credits: </p>
          <p>{totalTokens}</p>
          </div>
          <div className="price-line">
          <p className='totalamount'>Total Amount: {totalTokens}X₹{price} : </p>
          <p className='price'>₹{totalTokens * price}</p>
          </div>
          {btnloading?(
            <button className= "pay-btn btn-loading">
            </button>
          ):(
            <button className= "pay-btn" onClick={handlePayment}>
            Pay
            </button>
          )}
        </div>
    </div>
    )}
    </>
  );
}

export default Checkout;
