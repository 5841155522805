import React from 'react';
import './Notification.css';

const Notification = ({notification, setshowNoti}) => {
  return (
    <div className='notification'>
        <p>{notification}</p>
        <i class='noti-x bx bx-x' onClick={() => setshowNoti(false)}></i>

    </div>
  )
}

export default Notification