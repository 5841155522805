import React, { useEffect } from 'react'
import { getStats } from '../../actions/adminAction'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../layout/Loder';

const Stats = () => {

    const {loading, totalPosts} = useSelector((state) => state.stats);
    const dispatch = useDispatch();
    useEffect(() => {
        // console.log("kua kr rha bhai??");
        dispatch(getStats());
    }, [])
    
  return (
    <>
    {loading? (
        <Loader/>) :(
    
    <div>
        <h1>Stats</h1>
        <h1>Total Posts: {totalPosts}</h1>
    </div>
        )}
    </>
  )
}

export default Stats