import React from 'react'

const Error = () => {
  return (
    <div>
        <h1>Some error occured. Try again later.</h1>
    </div>
  )
}

export default Error