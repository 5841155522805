import React from 'react';
import './PaymentStatus.css';

const PaymentStatus = ({ status }) => {
  let statusColor = '';

  switch (status) {
    case 'captured':
      statusColor = 'green';
      break;
    case 'failed':
      statusColor = 'red';
      break;
    case 'created':
      statusColor = 'blue';
      break;
    default:
      statusColor = 'gray'; // Default color for unrecognized statuses
      break;
  }

  return (
    <div className={`payment-status ${statusColor}`}>
      {status==="captured"?"Paid":status=="created"?"Pending":"Failed"}
    </div>
  );
};

export default PaymentStatus;
