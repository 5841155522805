import React from 'react';
import "./Deletepopup.css";

const DeletePopup = ({handleDelete, setdeletepopup}) => {
    const deleteHandler = () => {
        setdeletepopup(false);
        handleDelete();
    }
  return (
    <>
    <div className="delete-blur-background"></div>
    <div className="popup">
    <div className='release-confirm'>
    <p>Are you sure you want to delete? Once deleted, node can't be recovered.</p>
        <button className="btn btn-orange btn-release" onClick={deleteHandler}>Yes</button>
        <button className="btn btn-release" onClick={() => setdeletepopup(false)}>No</button>
    </div>
    </div>
    </>
  )
}

export default DeletePopup