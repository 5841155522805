import React from 'react';
import './Contact.css'
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <div className='contact'>
      <Helmet>
        <title>Contact us - Dmyor</title>
        <meta name="description" content="Feel free to reach out to us anytime. Whether you have questions, feedback, or need assistance, we're here to help. " />
        <meta property="og:title" content="Contact us - Dmyor"/>
        <meta property="og:description" content="Contact us - Dmyor" />
        <meta property="og:image" content="https://res.cloudinary.com/dkgg6mdtb/image/upload/v1695966172/android-icon-144x144_ildr9u.png" />
        <meta property="og:url" content="https://www.dmyor.com/contact" />
        <meta property="og:type" content="website" />
      </Helmet>
      <h1>Contact Us</h1>
      <p>
      Feel free to reach out to us anytime. Whether you have questions, feedback, or need assistance, we're here to help. You can contact our support team by emailing us at <a href="mailto:support@dmyor.com">support@dmyor.com</a>. We value your input and will respond promptly to your inquiries. 
      </p>
    </div>
  )
}

export default Contact;
