import {
    CREATE_POST_REQUEST,
    CREATE_POST_SUCCESS,
    CREATE_POST_FAIL,
    ALL_POSTS_REQUEST,
    ALL_POSTS_SUCCESS,
    ALL_POSTS_FAIL,
    CHANGE_STATUS_REQUEST, 
    CHANGE_STATUS_SUCCESS,
     CHANGE_STATUS_FAIL,
     SAVED_POSTS_REQUEST,
     SAVED_POSTS_SUCCESS,
     SAVED_POSTS_FAIL,
    CLEAR_ERRORS
} from '../constants/postConstants';

//post reducer
export const postReducer = (state = { posts: [], totalPosts:0, totalPages:0}, action) => {
    switch(action.type){
        case ALL_POSTS_REQUEST:
            return{
                loading:true,
                posts:[],
                totalPosts:0,
                totalPages:0,
            }
        case ALL_POSTS_SUCCESS:
            var totalPages = Math.floor(action.payload.postCount/12);
            if(action.payload.postCount%12 != 0)totalPages++;
            
            return{
                loading:false,
                posts:action.payload.postToSend,
                totalPosts: action.payload.postCount,
                totalPages: totalPages,
            }
        case ALL_POSTS_FAIL:
            return{
                loading: false,
                error: action.payload,
            }
            case CHANGE_STATUS_REQUEST:
            return{
                ...state,
                loading:true
            }
        case CHANGE_STATUS_SUCCESS:
           const updatedposts = state.posts.map((post) => {
                if(post._id.toString() === action.payload._id.toString()){
                    return action.payload;
                }
                return post;
            })
            return{
                loading: false,
                posts: updatedposts,
            }
        case CHANGE_STATUS_FAIL:
            return{
                loading:false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null,
            }
        default:
            return state;
    }
}


//new post reducer
export const newpostReducer = (state = { post: {} }, action) => {
    switch(action.type){
        case CREATE_POST_REQUEST:
            return {
                loading:true,
            };
        case CREATE_POST_SUCCESS:
            // console.log("post creation successful");
            return{
                ...state,
                loading:false,
                post: action.payload,
            };
        case CREATE_POST_FAIL:
            return{
                ...state,
                loading:false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
                return {
                  ...state,
                  error: null,
                };
          
        default:
                return state;
            }
    }

export const savedPostsReducer = (state = { savedposts: [], postids: []}, action) => {
    switch(action.type){
        case SAVED_POSTS_REQUEST:
            return{
                loading:true,
                savedposts:[],
                postids:[]
            }
        case SAVED_POSTS_SUCCESS:
            // console.log("Savedpostlog", action.payload);
            return{
                ...state,
                loading:false,
                savedposts: action.payload,
                postids: action.payload.map(savedPost =>savedPost._id)
            }
        case SAVED_POSTS_FAIL:
            return{
                ...state,
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null,
            }
        default:
            return state;
    }
}