import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";

const PublicRoute = () => {
    const location = useLocation();
    const {loading, isAuthenticated} = useSelector((state) => state.user);
    // console.log(location.pathname);
    const isFormatMatched = /^\/s\/\w+$/.test(location.pathname);
    
    var redirect = new URLSearchParams(location.search).get('next');
    if(!redirect){
        redirect = "/dashboard";
    }
    // console.log(redirect);
    return(
        <>
        {loading===false && (
            isFormatMatched?<Outlet/>:
             isAuthenticated ?  <Navigate to={redirect} />: <Outlet /> 
        ) }
        </>
    )
}

export default PublicRoute;