import React, {useState} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './ShareLink.css';
const Sharelink = ({setshowsharelink, postId}) => {
    const link = `${process.env.REACT_APP_DOMAIN}/s/${postId}`;
    const tooltip = (
        <Tooltip id={`tooltip-${postId}`}>Copy Link</Tooltip>
    );
    const [copied, setcopied] = useState(false);
  return (
    <div className="popup">
        <div className='share'>
            <h2>Share Node</h2>
            <p>Anyone with the link can view this node.</p>
            {/* <p>{link}</p> */}
                <div className="button-box">
            <button 
            className='btn-copy'
            onClick={() => {
                navigator.clipboard.writeText(link);
                setcopied(!copied);
            }
            }>
            <i 
            class='bx bx-link-alt'
            ></i>
            {copied?"Link Copied": "Copy Link"}
            </button>
            <button onClick={() => setshowsharelink(false)} className='close-btn'>
            Close
            </button>
            </div>
        </div>
    </div>
  )
}

export default Sharelink