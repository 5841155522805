import React, { useState } from 'react';
import Select from 'react-select';

const Dropdown = ({setStatus, selectedOption, setselectedOption}) => {
  

  const options = [
    { value: 'standby', label: 'Standby', color: 'blue', background:'rgb(255, 255, 133)' },
    { value: 'active', label: 'Active', color: 'green',background:'rgb(170, 255, 170)' },
  ];
  const releaseOption = [
    { value: 'released', label: 'Released', color: 'rgb(255, 123, 0)',background:'rgb(255, 246, 235)' }
  ]

 
 
  const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });
  
  const colourStyles = {
    control: (styles, { isFocused }) => ({
        ...styles,
        // border: isFocused ? '2px solid orange' : '2px solid orange',
        backgroundColor: 'white',
        outline: 'none',
        // ':hover': {
        //   ...styles[':hover'],
        //   border: '2px solid blue',
        // },
      }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const background = data.background;
      const color = data.color;
  
      return {
        ...styles,
        backgroundColor: isDisabled ? undefined : isSelected ? background : isFocused ? background : undefined,
        color: isDisabled ? '#ccc' : isSelected ? 'black' : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled ? isSelected ? color : color : undefined,
        },
        ...dot(color),
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };
    
  
  

  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
    setStatus(selectedOption.value);
  };

  return (
    <div>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={selectedOption.value === "released"?releaseOption:options}
        styles={colourStyles}
        isSearchable={false}
      />
    </div>
  );
};

export default Dropdown;
