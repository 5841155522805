import React from 'react'
import { Link } from 'react-router-dom'

const CheckoutSuccess = () => {
  return (
    <div>
        <h1>Congratulations! Payment succeeded</h1>
        <Link to="/dashboard">
        <button className='btn'> Go to Dashboard</button>
        </Link>
    </div>
  )
}

export default CheckoutSuccess