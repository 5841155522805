import {React, useState} from 'react'
import { useDispatch } from 'react-redux';
import { changeStatus } from '../../actions/postAction';
import Switch from 'react-switch';
import moment from 'moment';
import './Release.css'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const Release = ({setShowPopup, post, setpost, setreleasedate, user}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [checked, setchecked] = useState(false);
    const [releasetext, setreleasetext] = useState('');
    const handleChange = (e) => {
        setchecked(!checked);
    }
    const handleConfirm = async () => {
        setShowPopup(false);
        document.body.style.overflow = 'auto';
        const data = {id: post._id, userId: user._id};
        if(releasetext.length>0){
          data.releasenote = releasetext;
        }
        const res = await dispatch(changeStatus(data));
        // console.log(res);
        if(res.success){
          toast.success("Released successfully");
          if(post.method === "digicoins"){
          navigate(`/dashboard/congratulations/${post._id}`);
          }else{
            setpost(res.post);
            setreleasedate(moment(res.post.releaseDate).format('DD-MM-yyyy'));
            // navigate(`/dashboard/note-details/${post._id}`);
          }
      }else{
          toast.error("Something went wrong");
        }
      };
    
      const handleCancel = () => {
        setShowPopup(false);
        document.body.style.overflow = 'auto';
      };
  return (
    <div className="popup">
    <h2>{post.method == 'digicoins'? "Release Node" : "Change Status"}</h2>
    {post.method == 'digicoins' ?
    (
      <>
      <p>Realeasing the node will will remove anonymity from your node. Your name would be visible and you would not be able to add furthur updates.</p>
      <label htmlFor="material-switch">
          <span>Add release message</span>
          <Switch
            checked={checked}
            onChange={handleChange}
            onColor="#F9861B"
            onHandleColor="#F9861B"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgb(255, 123, 0)"
            activeBoxShadow="0px 0px 1px 10px rgba(255, 123, 0, 0.2)"
            height={15}
            width={35}
            className="react-switch"
            id="material-switch"
          />
    </label>
    {checked ? <textarea
  id="releasetext"
  value={releasetext}
  onChange={(e) => setreleasetext(e.target.value)}
/> : null}
    </>
    ):
    (
    <>
    <p>Changing status of your node will shift node status from Active to Completed.</p>
    </>)
  }
    
    <div className='release-confirm'>
    <p>{post.method === "digicoins"?"Are you sure you want to release? Once released, the status can't be reverted back.":"Are you sure you want to change status? Once changed, the status can't be reverted back."}</p>
        <button className="btn btn-orange btn-release" onClick={handleConfirm}>Yes</button>
        <button className="btn btn-release" onClick={handleCancel}>No</button>
    </div>
    </div>
  )
}

export default Release