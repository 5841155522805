import React from "react";
import "./Footer.css"; // Import the CSS file for styling
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="footer-container">
      <p>&copy; 2023 Dmyor</p>
      <div className="footer-links">
        <div className="footer-container-1">
        <Link to="/about" className="footer-link">About</Link>
        <Link to="/terms" className="footer-link">Terms of Service</Link>
        <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
        </div>
        <div className="footer-container-2">
        <Link to="/contact" className="footer-link">Contact</Link>
        <Link to="/refund-policy" className="footer-link">Refund Policy</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
