import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { login, loginWithGoogle } from '../actions/userAction';
import './Login.css';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [btnloading, setbtnloading] = useState(false);

  const validateEmail = (value) => {
    if (!value) {
      return 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      return 'Invalid email address';
    }
    return '';
  };

  const validatePassword = (value) => {
    if (!value) {
      return 'Password is required';
    }
    return '';
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const error = validateEmail(value);
    setErrors((prevErrors) => ({ ...prevErrors, email: error }));
    setEmail(value);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    const error = validatePassword(value);
    setErrors((prevErrors) => ({ ...prevErrors, password: error }));
    setPassword(value);
  };

  const handleLogin = async() => {
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);

    if (!emailError && !passwordError) {
      setbtnloading(true);
      try{
        await dispatch(login(email, password));
      }catch(error){
        toast.error(error);
      }finally{
        setbtnloading(false);
      }
    } else {
      setErrors({ email: emailError, password: passwordError });
    }
  };

  const handleGoogleAuth = () => {
    // console.log('Google login initiated');
    dispatch(loginWithGoogle());
  };
  

  return (
    <div className="popup-container">
      <Helmet>
  <title>Login - Dmyor</title>
  <meta name="description" content="Welcome back to Dmyor. Sign in to explore what people all around world are writing for themselves and be a part of their journey in their own words." />

  <meta property="og:title" content="Login to Dmyor" />
  <meta property="og:type" content="website" />
  <meta property="og:description" content="Login to Dmyor - Document, progress, and release as you move forward in your journey. Join us in bending reality!" />
  <meta property="og:image" content="https://res.cloudinary.com/dkgg6mdtb/image/upload/v1695966172/android-icon-144x144_ildr9u.png" /> {/* Using the specified image URL */}
  <meta property="og:url" content="https://dmyor.com/login" /> 
</Helmet>

      <div className="blur-background"></div>
      <div className="signup-popup">
        <h2>
          Login to <span>Dmyor</span>
        </h2>
        <div className="signinform">
          <div>
            <input
              type="email"
              placeholder="username or email"
              id="email"
              className={`${errors.email? '':'add-margin'}`}
              value={email}
              onChange={handleEmailChange}
              required
            />
            {errors.email && <p className="error-text">{errors.email}</p>}
          </div>
          <div>
            <input
              type='password'
              placeholder="password"
              id="password"
              className={`${errors.password? '':'add-margin'}`}
              value={password}
              onChange={handlePasswordChange}
              required
            />
            {errors.password && <p className="error-text">{errors.password}</p>}
          </div>
          <div className="forgot-password">
          <Link to={"/password-reset"}>Forgot password?</Link>
          </div>
          {btnloading?(
            <button className= "btn-submit btn-loading">
            </button>
          ):(
            <button className= "btn-submit" onClick={handleLogin}>
            Log In
            </button>
          )}
        </div>
        <div className="divider">
          <hr className="divider-line" />
          <span className="divider-text">OR</span>
          <hr className="divider-line" />
        </div>
        <button className="btn popup-btn" onClick={handleGoogleAuth}>
          <i className="bx bxl-google"></i> Sign in with Google
        </button>
        <p className="createnew">
          Don't have an account? <Link to="/signup"><span>Create one</span></Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
