import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  allUsersReducer,
  forgotPasswordReducer,
  profileReducer,
  userDetailsReducer,
  userReducer,
} from "./reducer/userReducer";
import{
  newpostReducer,
  postReducer,
  savedPostsReducer
} from "./reducer/postReducer"
import{
  searchReducer,
} from "./reducer/searchReducer";
import {
  adminReducer,
} from "./reducer/adminReducer"
const reducer = combineReducers({
    user: userReducer,
    profile: profileReducer,
    forgotPassword: forgotPasswordReducer,
    allUsers: allUsersReducer,
    userDetails: userDetailsReducer,
    newpost: newpostReducer,
    posts: postReducer,
    search:searchReducer,
    savedposts: savedPostsReducer,
    stats: adminReducer,
});

let initialState = {

};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
  +  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
