import {
    CHECKOUT_REQUEST,
    CHECKOUT_FAIL,
    CHECKOUT_SUCCESS
} from "../constants/paymentConstants";
import axios from "axios";
import {toast} from "react-toastify";
import config from "../config";

const serverurl = config.apiUrl;
const serverConfig = {
    withCredentials:true,
}
//checkout request
export const checkoutRequest = (order, user) => async(dispatch) => {
    try{
        dispatch({type: CHECKOUT_REQUEST});
        const response = await axios.post(
            `${serverurl}/api/v1/checkout`,
            order,
            serverConfig
        );
        const{data:{url}} = response;
        dispatch({type:CHECKOUT_SUCCESS, payload:url});
        window.location.href = url;

    }catch(error){
        toast.error(error.response.statusText);
        dispatch({type: CHECKOUT_FAIL, payload: error.response.data.message});
    }
}

export const razorpayCheckoutRequest = (orderDetails, user) => async(dispatch) => {
    try{
        dispatch({type: CHECKOUT_REQUEST});
        const {data: {key}}= await axios.get(`${serverurl}/api/v1/getkey`, serverConfig);
        const {data: {order}} = await axios.post(
            `${serverurl}/api/v1/razorpaycheckout`,
            orderDetails,
            serverConfig
        );
        key.toString();
        var options = {
            "key":key, 
            "amount": order.amount, 
            "currency": "INR",
            "name": "Dmyor",
            "description": "buy D-credits",
            "order_id": order.id, 
            "callback_url": `${serverurl}/api/v1/verifypayment`,
            "prefill": {
                "name": user.name,
                "email": user.email,
            },
            "notes": {
            },
            "theme": {
                "color": "#FF7B00"
            }
        };
        const razor = new window.Razorpay(options);
        razor.open();
    }catch(error){
        toast.error(error.response.statusText);
        dispatch({type: CHECKOUT_FAIL, payload: error.response.data.message});
    }
}

//phonepe
export const phonepeCheckoutRequest = (orderDetails, user) => async(dispatch) => {
    try{
        // console.log(orderDetails);
        dispatch({type: CHECKOUT_REQUEST});
        const response = await axios.post(
            `${serverurl}/api/v1/phonepecheckout`,
            orderDetails,
            serverConfig
        );
        window.location.href = response.data.url;
    }catch(error){
        toast.error(error.response.statusText);
        dispatch({type: CHECKOUT_FAIL, payload: error.response.data.message});
    }
}

//get price
export const getPrice = () => async(dispatch) => {
    try{
        const {data:{price}} = await axios.get(
            `${serverurl}/api/v1/getprice`,
            serverConfig
            );
        return price.price;
    }catch(error){
        toast.error(error.response.statusText);
    }
}

export const getPaymentHistory = async() => {
    try{
        const {data:{history}} = await axios.get(
            `${serverurl}/api/v1/paymenthistory`,
            serverConfig
            );
        // console.log(history);
        return history;
    }catch(error){

    }
}