import React, { useEffect, useState } from 'react';
import { Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import './MyNotes.css';
import Note from './Note';
import { getAllPosts, getSavedPosts } from '../../actions/postAction';
import Loader from '../../layout/Loder';
import TokenPopup from './TokenPopup';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const MyNotes = () => {
  const dispatch = useDispatch();
  const { loading, error, posts, totalPages, totalPosts } = useSelector((state) => state.posts);
  const { user } = useSelector((state) => state.user);
  const [showPopup, setShowPopup] = useState(false);
  const [tokenCount, setTokenCount] = useState(5);
  const [showWarning, setShowWarning] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const getPosts = async () => {
    const res = await dispatch(getAllPosts(currentPage));
    // console.log(res+" lamulamu "+res.status);
    if (res) {
      if (res.status === 401) {
        toast.error('Log in to access this event');
        window.open('/', '_self');
        return;
      }
    }
  };

  useEffect(() => {
    getPosts();
    dispatch(getSavedPosts());
    setShowWarning(user.tokens === 0);
  }, [dispatch, error, currentPage]);

  const closePopup = () => {
    setShowPopup(false);
  };

  const updateTokenCount = (newCount) => {
    setTokenCount(newCount);
  };

  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div>
      <Helmet>
        <title>My Nodes</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <div className='mynodes'>
          <div className="top-right-container">
            <div className="rounded-container">
              <div className="rounded-image">
                D
              </div>
              <OverlayTrigger
                placement="bottom"
                show={showWarning}
                overlay={
                  <Tooltip id="tooltip-warning">
                    You have low tokens.<br />
                    <Link onClick={() => setShowWarning(false)}>Dismiss</Link>
                  </Tooltip>
                }
              >
                <span className={`number ${showWarning ? 'text-danger' : ''}`}>{user.tokens}</span>
              </OverlayTrigger>
              <i className="bx bx-plus bx-sm plusicon" onClick={() => setShowPopup(!showPopup)}></i>
            </div>
            <Link to="new" className="btn btn-orange">
              New Node
            </Link>
          </div>
          {posts.length > 0 ? (
            <div>
              <div className="totalposts">
                <p>{totalPosts} {totalPosts>1? "nodes": "node"}</p>
              </div>
              <div className="notes row">
                {posts.map((post) => (
                  <div className="single-note col-lg-4 col-md-4 col-sm-12" key={post._id}>
                    <Note post={post} />
                  </div>
                ))}
              </div>
              <div className="pagination">
              {
                totalPages > 1 ? (
                  <div className="pagination-controls">
                  <button className="btn btn-orange" onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>
                    Previous
                  </button>
                  <button onClick={() => goToPage(1)} className={currentPage === 1 ? 'btn btn-orange' : 'btn'}>
                    1
                  </button>
                  {currentPage > 2 && <span className="ellipsis">...</span>}
                  {Array.from({ length: Math.min(totalPages-currentPage, 3) }, (_, index) => {
                    if(currentPage==1 && currentPage+index+1 == totalPages)return null;
                    if(currentPage>1 && currentPage+index == totalPages)return null; 
                    return(
                    <button
                      key={index + currentPage}
                      onClick={() => goToPage(currentPage>1?index + currentPage:index+currentPage+1)}
                      className={(currentPage>1?(currentPage === index + currentPage):(currentPage===index+currentPage+1)) ? 'btn btn-orange' : 'btn'}
                    >
                      {currentPage>1?index + currentPage:index+currentPage+1}
                    </button>
                    )
                  })}
                  {totalPages > 3 && currentPage < totalPages - 3 && <span className="ellipsis">...</span>}
                  {totalPages > 1 && (
                    <button
                      onClick={() => goToPage(totalPages)}
                      className={currentPage === totalPages ? 'btn btn-orange' : 'btn'}
                    >
                      {totalPages}
                    </button>
                  )}
                  <button className="btn btn-orange" onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>
                    Next
                  </button>
                </div>
              ):(
                <>
                </>
              )
              }
              </div>
            </div>
          ) : (
            <div className="nomatch">
              <p>No nodes yet</p>
            </div>
          )}
          {showPopup && (
                <TokenPopup tokenCount={tokenCount} updateTokenCount={updateTokenCount} closePopup={closePopup} />
              )}
        </div>
      )}
    </div>
  );
};

export default MyNotes;
