import React from 'react'
import { Link } from 'react-router-dom'
import './Building.css';
const Building = () => {
  return (
    <div className="building">
    <div>
        <h1>This page is currently under construction. It will be available shortly.</h1>
        <Link to="/">
        <button className="btn btn-orange">Home</button>
        </Link>
    </div>
    </div>
  )
}

export default Building