import React, {useEffect, useState} from 'react'
import './Congrats.css';
import Sharelink from './Sharelink';
import { useParams, useNavigate } from 'react-router';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import config from "../../config";

const serverurl = config.apiUrl;
const serverConfig = {
  withCredentials:true,
}

const Congrats = () => {
    const {id} = useParams();
    const [clicked, setclicked] = useState(false);
    const navigate = useNavigate();
    const link = `${process.env.REACT_APP_URL}/s/${id}`;
    const [post, setpost] = useState({});

    useEffect(() => {
      const fetchPost = async() => {
        try{
        const {data} = await axios.get(`${serverurl}/api/v1/myposts/${id}`, serverConfig);
        // console.log(data); 
        // console.log(data.post.status);
        if(data.post.status == "false"){
          toast.error("This post is not released yet");
          navigate("/");
        }
        setpost(data.post);
        }catch(error){
          toast.error(error.response.data.message);
          navigate("/");
        }
      }
      fetchPost();
    }, [])
    
    const handleClick = () =>{
      navigator.clipboard.writeText(link);
      setclicked(!clicked);
    }

  return (
    <div className="release-success">
        <p className='congo'>Congratulations!</p>
        <p className='congo'><span>Node Released - Entered new timeline</span></p>
        <p>Here's the link to share you node</p>
        <div className="">
            <button className="btn btn-share" onClick={handleClick}
            >{clicked?"Link Copied":"Copy Link"}</button>
        </div>
        <div className="success-message">
          <p>It took you <span>{post.totalTime}</span> to release this node.</p>
        <p>Releasing a node is a significant accomplishment, marking the completion of something you wrote thinking of this moment. Congratulations on reaching this milestone!</p>
        <p> If you're ready to embark on a new journey, here's a way forward.</p>
        <button className='btn btn-orange'><Link to="/dashboard/new" className='new-node-btn'>New Node</Link></button>
        </div>
    </div>
  )
}

export default Congrats