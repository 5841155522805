export const CREATE_POST_REQUEST = "CREATE_POST_REQUEST";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_FAIL = "CREATE_POST_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const ALL_POSTS_REQUEST = "ALL_POSTS_REQUEST";
export const ALL_POSTS_SUCCESS = "ALL_POSTS_SUCCESS";
export const ALL_POSTS_FAIL = "ALL_POSTS_FAIL";

export const CHANGE_STATUS_REQUEST = "CHANGE_STATUS_REQUEST";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_FAIL = "CHANGE_STATUS_FAIL";

export const SAVED_POSTS_REQUEST = "SAVED_POST_REQUEST";
export const SAVED_POSTS_SUCCESS = "SAVED_POST_SUCCESS";
export const SAVED_POSTS_FAIL = "SAVED_POSTS_FAIL";