import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router';
import {useSelector } from 'react-redux';
import axios from 'axios';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {toast} from 'react-toastify';
import Loader from '../layout/Loder';
import Sharelink from '../components/UserPortal/Sharelink';
import UpdateMessage from '../components/UserPortal/UpdateMessage';
import config from '../config';
import { Helmet } from 'react-helmet';
import formatDate from '../util';


const PublicSearch = () => {
  const navigate = useNavigate();
  const {isAuthenticated} = useSelector((state) => state.user);
  const {id} = useParams();
  const [post, setpost] = useState('');
  const [date, setdate] = useState();
  const [releasedate, setreleasedate] = useState();
  const [showsharelink, setshowsharelink] = useState(false);
  const [dateString, setdateString] = useState();
  const [maxDate, setmaxDate] = useState();

  useEffect(() => {
    // console.log(isAuthenticated);
    if(isAuthenticated){
        const url = `/dashboard/note-details/${id}`
        const link = `?next=${encodeURIComponent(url)}`;
        navigate(url);
        return;
    }
    const fetchPost = async() => {
      const serverurl = config.apiUrl;
      try{
        const {data} = await axios.get(`${serverurl}/api/v1/publicsearch/${id}`, {withCredentials:true});
        setpost(data.post);
        const thisdate = new Date(data.post.date);
        const dateString= formatDate(thisdate);
        setdate(dateString);
        if(data.post.status == 'released'){
          const thisdate = new Date(data.post.releaseDate);
          const dateString= formatDate(thisdate);
          setreleasedate(dateString);
        }
      }catch(error){
        toast.error(error.response.data.message);
        navigate("/s");
      }
      
      
    }
      fetchPost();
  }, [isAuthenticated]);
  
  
  const shareLink = (e) => {
    e.preventDefault();
    setshowsharelink(!showsharelink);
  }

  const shareTooltip = (
    <Tooltip id="share-tooltip">Share</Tooltip>
  );
  const createdbyTooltip = (
    <Tooltip id="share-tooltip">Created by</Tooltip>
  );
  const ttcTooltip = (
    <Tooltip id="share-tooltip">Time to release</Tooltip>
  );
  return (
    <>
    <Helmet>
  <title>Search Results - Dmyor</title>
  <meta name="description" content="Explore search results on Dmyor" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://www.dmyor.com/s" /> 

  <meta property="og:title" content="Explore - Dmyor" />
  <meta property="og:type" content="website" />
  <meta property="og:description" content="Explore Dmyor" />
  <meta property="og:image" content="https://res.cloudinary.com/dkgg6mdtb/image/upload/v1695966172/android-icon-144x144_ildr9u.png" /> {/* Using the specified image URL */}
  <meta property="og:url" content="https://www.dmyor.com/s" /> 
</Helmet>

    {/* <Navbar/> */}
    {(post)? (
      <div className="note-details">
      <div className="content">
        <div className="details-header">
          <div className='options-left'>
            {(post.status === "released")?(
              <div>
              <OverlayTrigger placement="top" overlay={createdbyTooltip}>
              {post.username? <p><span className=''> <i class='bx bxs-user options-left-icons' ></i> {post.username}</span></p>: <></>}
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={ttcTooltip}>
              {post.totalTime?<p><span className=''><i class='bx bx-time options-left-icons'></i> {post.totalTime}</span></p>:''}
              </OverlayTrigger>
              </div>
            ):(
              <></>
            )}
        
        </div>
        <div className='options'>
          {(post.method === "digicoins" )?(
             <OverlayTrigger placement="top" overlay={shareTooltip}>
             <i className="bx bx-share-alt detailshare options-right-icons" onClick={shareLink}></i>
           </OverlayTrigger>
          ):(
            <>
            </>
          )}
          
        </div>
        </div>
        <p>{dateString}</p>
        <div className="desc">
        
        {
          post.note.split('\n').map((line, index) => (
            <p key={index}>
              {line}
              {/* <br /> */}
            </p>
          ))
        }
        </div>
        <div className="status-bar">
          <div className="share-mobile">
          
        {(post.method === "digicoins" )?(
             <OverlayTrigger placement="top" overlay={shareTooltip}>
             <i className="bx bx-share-alt detailshare" onClick={shareLink}></i>
           </OverlayTrigger>
          ):(
            <>
            </>
          )}
        
      </div>
      <div className="flex-container">
        <div className="left">
        </div>
        <div className='right'>
        <p>{post.status =="released"?`${date} - ${releasedate}` : `${date}`}</p>
          {/* {post.username? <p><span className='release-span'>Created by: {post.username}</span></p>: <></>} */}
          <div className={post.status=="released" ? "released-dot" :(post.status=="active") ? "active-dot":"standby-dot"}></div>
          <p className={post.status=="released" ? "status-released-text" :(post.status=="active") ? "status-active-text":"status-standby-text"}> {post.status=="released"?"Released":(post.status=="active") ? "Active":"Standby"}</p>
          {/* {post.totalTime?<p>{post.totalTime}</p>:''} */}
        </div>
      </div>
          
        </div>
        <div className="extra-space"></div>
        <div className="updates">
          {post.updates && post.updates.length>0 ? (
            post.updates.map((update, index) => (
              <UpdateMessage note={update.update} date = {update.date} maxDate={maxDate} setmaxDate={setmaxDate}/>
            ))
          ):(<></>)}
          {post.releasenote ? (
          <div className="desc">
            <p className='releasenote'>
            {
              post.releasenote.split('\n').map((line, index) => (
                <p key={index}>
                  {line}
                  <br />
                </p>
              ))
            }
            </p>
        </div>
        ):(
        <></>
      )
        }
          </div>
        
        
      </div>
     
      
      {showsharelink &&(
        <div className="overlay">
              <i class='bx bx-x' onClick={() => setshowsharelink(false)}></i>
            <Sharelink setshowsharelink={setshowsharelink}  postId={post._id}/>
        </div>
      )}
    </div>
    ):(
      <>
      <Loader/>
      </>
    )}
    </>
  );
  
};

export default PublicSearch;
