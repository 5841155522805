import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
const RefundPolicy = () => {
  return (
    <div className='terms'>
       <Helmet>
        <title>Refund Policy | Dmyor </title>
        <meta name="description" content="Learn how Dmyor uses user data." />
        <meta property="og:title" content="Dmyor Privacy Policy - Protecting Your Information" />
        <meta property="og:description" content="Learn how Dmyor uses user data." />
        <meta property="og:image" content="https://res.cloudinary.com/dkgg6mdtb/image/upload/v1695966172/android-icon-144x144_ildr9u.png" />
        <meta property="og:url" content="https://www.dmyor.com/privacy-policy" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Link className='note-link' to="/">
        <p className='refund-p'><i class='bx bxs-home-alt-2'></i>Home</p>
        </Link>
        <h1>
        Refund Policy
        </h1>
        <p>
        Thank you for your interest in DMYOR. We take pride in delivering exceptional experiences to all our users. When you make a purchase on our platform, it is important to understand our refund policy. By doing so, you agree to abide by our Terms & Conditions and refund policy.
<br />
Our refund policy is straightforward:
<br />
You acknowledge that services purchased from DMYOR are non-transferable and non-refundable. DMYOR is not obligated to refund any fees or charges, whether in full or in part, under any circumstances. 
<br />
In the event that a refund is granted, the refunded amount may be provided as cash or as a credit toward the purchase of other DMYOR services of equivalent value, at our sole discretion.
<br />
We value your satisfaction and are here to assist you. If you have any questions or concerns about our refund policy, please feel free to contact our customer support team. Your journey with DMYOR is important to us, and we are committed to ensuring a positive experience for all our users.
</p>
    </div>
  )
}

export default RefundPolicy