import React from 'react';
import { useSelector } from 'react-redux';
import './Saved.css';
import SearchResults from './SearchResults';
import Loader from '../../layout/Loder';
import { Helmet } from 'react-helmet';



const Search = () => {
  
  const {loading, savedposts} = useSelector((state)=> state.savedposts);
 
  return (
    <div className='search'>
      <Helmet>
        <title>Saved Nodes</title>
      </Helmet>
      {loading ? 
      (<Loader/>):(
        <>
        <h2 className='savedheading'>Saved Nodes</h2>
        {savedposts.length > 0 ? (
          <div className="saved row">
            {
            savedposts.map((post) => (
                // console.log(post),
              <div className="single-note col-lg-4 col-md-4 col-sm-12">
                <SearchResults key={post._id} post={post} />
              </div>
            ))}
          </div>
        ) : (
          <div className="nomatch">
          <p>No nodes saved.</p>
          </div>
        )}
        </>
      )}
      
    </div>
  );
};

export default Search;
