import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./BottomNavbar.css"

const BottomNavbar = () => {
	const location = useLocation();
  return (
    <div className="mobile-bottom-nav">
		{/* {console.log(location.pathname)} */}
	<div className={`mobile-bottom-nav__item ${location.pathname === '/dashboard/new' ? 'icon-active' : ''}`}>
		<div className="mobile-bottom-nav__item-content">
		<Link to="new">
		<i className='bx bx-plus bottom-nav-icons'></i>
		</Link>
		</div>		
	</div>
	<div className={`mobile-bottom-nav__item ${location.pathname === '/dashboard' ? 'icon-active' : ''}`}>
		<div className="mobile-bottom-nav__item-content">
			<Link to="/dashboard">
			<i className='bx bx-notepad bottom-nav-icons'></i>
			</Link>
		</div>		
	</div>
	<div className={`mobile-bottom-nav__item ${location.pathname === '/dashboard/search' ? 'icon-active' : ''}`}>		
		<div className="mobile-bottom-nav__item-content">
			<Link to="search">
			<i className='bx bx-search bottom-nav-icons'></i>
			</Link>
		</div>
	</div>
	<div className={`mobile-bottom-nav__item ${location.pathname === '/dashboard/saved' ? 'icon-active' : ''}`}>
		<div className="mobile-bottom-nav__item-content">
			<Link to = "saved">
			<i className='bx bx-bookmark bottom-nav-icons'></i>
			</Link>
		</div>		
	</div>
	<div className={`mobile-bottom-nav__item ${location.pathname === '/dashboard/account' ? 'icon-active' : ''}`}>
		<div className="mobile-bottom-nav__item-content">
			<Link to = "account">
			<i className='bx bxs-user bottom-nav-icons'></i>
			</Link>
		</div>		
	</div>
</div> 
  );
};

export default BottomNavbar;
