// config.js
 
  // const config = {
  //   apiUrl: "http://localhost:5000",
  // };
  //to use
  const config = {
      apiUrl: "https://ygoenv2gdj2fdn5sxnllxux2xi0lmunr.lambda-url.ap-south-1.on.aws",
    };
    // const config  
    //  {
    //   apiUrl: "https://gafwf5w4eukveuw2uopzwibpjq0dtebk.lambda-url.us-east-1.on.aws",
  // };
// const config = {
//     apiUrl: "https://g9pigvs9l9.execute-api.us-east-1.amazonaws.com/dev",
//   };
  
//test
// const config = {
//   apiUrl: "https://e5jykz7mt7.execute-api.us-east-1.amazonaws.com/dev",
// };
export default config;