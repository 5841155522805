
  import React, { useState, useEffect } from 'react';
  import './App.css';
  import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
  import store from './store';
  import Home from './components/Home';
  import Navbar from './components/Navbar';
  import MyNotes from './components/UserPortal/MyNotes';
  import DashBoard from './components/UserPortal/DashBoard';
  import NoteDetails from './components/UserPortal/NoteDetails';
  import Account from './components/UserPortal/Account';
  import NewNote from './components/UserPortal/NewNote';
  import Signup from './components/Signup';
  import { loadUser } from './actions/userAction';
  import ProtectedRoute from './components/Route/ProtectedRoute';
  import Login from './components/Login';
  import PublicRoute from './components/Route/PublicRoute';
  import Checkout from './components/UserPortal/Checkout';
  import CheckoutSuccess from './components/UserPortal/CheckoutSuccess';
  import Search from './components/UserPortal/Search';
  import Saved from './components/UserPortal/Saved';
  import PublicSearch from './components/PublicSearch';
  import Congrats from './components/UserPortal/Congrats';
  import Privacy from './components/Privacy';
import Terms from './components/Terms';
import About from './components/About';
import Admin from './components/Admin/Admin';
import Stats from './components/Admin/Stats';
import Prices from './components/Admin/Prices';
import Reset from './components/Utility/Reset';
import ResetPassword from './components/Utility/ResetPassword';
import Error from './components/Utility/Error';
import Building from './components/Utility/Building';
import Contact from './components/Contact';
import Pricing from './components/Pricing';
import { Helmet } from 'react-helmet';
import RefundPolicy from './components/RefundPolicy';
import Loader from './layout/Loder';
import PaymentHistory from './components/UserPortal/PaymentHistory';
import Home2 from './components/Home2';

  function App() {
    const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const dispatchUser = async() => {
     await store.dispatch(loadUser());
     setIsLoading(false);
    }
    useEffect(() => {
      dispatchUser(); 
    }, []);

    const handleCreateClick = () => {
      setIsCreatePostModalOpen(true);
    };

    window.addEventListener("contextmenu", (e) => e.preventDefault());
    return (
      <div className="App">
         <Helmet>
        <title>Dmyor.com</title>
        <meta name="description" content="Document what is yet to be done in your words." />
      </Helmet>
      {isLoading? <Loader/> :(
        <Router>
        {/* <Routes>
      </Routes> */}
        <Routes>
        <Route element = {<PublicRoute/>}>
        <Route path = "/" element={<Navbar/>} >
        <Route index element={<Home/>} />
        <Route path = "/about" element={<About/>} />
        <Route path = "/signup" element={<Signup/>} />
        <Route path = "/login" element={<Login/>}  />
        <Route path ="/s" element = {<Search/>}/>
        <Route path='/s/:id' element = {<PublicSearch/>}/>
        <Route path="/contact"  element = {<Contact />}/>  
        {/* <Route path="/pricing"  element = {<Pricing />}/>   */}
        </Route>
        </Route>
        <Route element = {<ProtectedRoute/>} >
        <Route  path =  "/dashboard" element = {<DashBoard onCreateClick={handleCreateClick} />} >
          <Route index element = {<MyNotes/>}/>
          <Route path = 'mynotes' element = {<MyNotes/>}/>
          <Route path = 'note-details/:id' element = {<NoteDetails/>} />
          <Route path = 'account' element = {<Account/>}/>
          <Route path = 'saved' element = {<Saved/>}/>
          <Route path= 'new' element = {<NewNote/>}/>
          <Route path="search" element = {<Search/>}/>
          <Route path = 'payment-history' element = {<PaymentHistory/>}/>
          <Route path = 'congratulations/:id' element = {<Congrats/>}/>
        </Route>
        <Route path='/admin' element={<Admin isAdmin={true}/>} >
          <Route index element={<Stats/>}/>
          <Route path='prices' element = {<Prices/>} />
          <Route path="search" element = {<Search/>}/>
        </Route>
        <Route path = "/checkout" element = {<Checkout/>} />
        <Route path= '/checkout-success' element = {<CheckoutSuccess/>}/>
        </Route>
        {/* <Route element={<ProtectedRoute isAdmin={true}/>}>
            <Route path='/admin' element={<Admin/>} >

            </Route>
        </Route> */}
        {/* <Route path='/s/:id' element = {<PublicSearch/>}/> */}
        <Route path='/error' element = {<Error/>}/>
        <Route path='/building' element = {<Building/>}/>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/privacy-policy"  element = {<Privacy />}/>  
        <Route path="/terms"  element = {<Terms />}/>  
        <Route path="/refund-policy"  element = {<RefundPolicy />}/>          
        <Route path="/password-reset"  element = {<Reset />}/>  
        <Route path="/resetpassword/:token"  element = {<ResetPassword />}/>  
        </Routes>
      </Router>  
      )}
      
      </div>
    );
  }

  export default App;
