import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { addUpdate } from '../../actions/postAction';
import {toast} from 'react-toastify'
import "./NewUpdate.css";

const NewUpdate = ({postId, setpost, setShowUpdate}) => {
    const dispatch = useDispatch();
    const [releasetext, setreleasetext] = useState('');
    const [loading, setloading] = useState(false);
    const submitUpdate = async() => {
        try{
            setloading(true);
            const data = {
                postId,
                updateNote: releasetext
            }
            await dispatch(addUpdate(data, setpost));
            setShowUpdate(false);
        }catch(error)
        {
            toast.error(error);
        }finally{
            setloading(false);
        }
    }
  return (
    <div className='popup'>
        <p className='reaching'>Reaching there...</p>
        <textarea
        id="releasetext"
        value={releasetext}
        onChange={(e) => setreleasetext(e.target.value)}
        />
        <div className="update">
        <button
        className="btn btn-orange"
        onClick={submitUpdate}
        >
        {loading?'Updating...':'Add Update'}
        </button>
        </div>
    </div>
    
  )
}

export default NewUpdate