import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const ProtectedRoute = () => {
    const { loading, isAuthenticated } = useSelector((state) => state.user);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is not authenticated and navigate to login page if needed
        if (!loading && !isAuthenticated) {
            const redirectPath = `/login?next=${encodeURIComponent(location.pathname)}`;
            navigate(redirectPath, { replace: true });
        }
    }, [loading, isAuthenticated, location.pathname, navigate]);

    return (
        <>
            {loading === false && isAuthenticated ? (
                <Outlet />
            ) : null}
        </>
    );
};

export default ProtectedRoute;
