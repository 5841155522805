import React, {useRef, useEffect} from 'react'
import './Home.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSpring, animated } from 'react-spring';
import TypeWriter from './Utility/Typewriter';
const Home = () => {

  // Define the animation properties based on the scroll position
  const props = useSpring({
    from: { opacity: 0, transform: 'scale(0.8)' },
    to: { opacity: 1, transform: 'scale(1)' },
  });
  const words = ['vision', 'goals', 'dreams', 'Journey'];

  return (  
    <animated.div style={props}>
    <>
    <div className='home'>
    <Helmet>
        <title>Dmyor: Document everything you want to do.</title>
        <meta name="description" content="Document your journey of what is yet to be done." />
        <meta property="og:title" content="Dmyor: Document your journey of what you want to do." />
        <meta property="og:description" content="Document your journeys, add updates as you progress, and release them when completed. " />
        <meta property="og:image" content="https://res.cloudinary.com/dkgg6mdtb/image/upload/v1695966172/android-icon-144x144_ildr9u.png" />
        <meta property="og:url" content="https://www.dmyor.com" />
        <meta property="og:type" content="website" />

        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "Action",
            "name": "Create New Node",
            "description": "Document your journey to what is yet to be done by you.",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://www.dmyor.com/dashboard/new",
              "inLanguage": "en-US",
              "actionPlatform": [
                "http://schema.org/DesktopWebPlatform",
                "http://schema.org/MobileWebPlatform"
              ]
            }
          }
        `}</script>
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Explore",
            "description": "Experience the journeys people are taking in their own words.",
            "url": "https://www.dmyor.com/s"
          }
        `}
        </script>
       
        {/* Structured data for About Us */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "AboutPage",
            "name": "About Us",
            "description": "Learn more about Dmyor",
            "url":"https://www.dmyor.com/about"
          }
        `}</script>

        {/* Structured data for Contact */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "ContactPage",
            "name": "Contact Us - Dmyor",
            "description": "Get in touch with us. Reach out to us for any queries or assistance.",
            "url":"https://www.dmyor.com/contact"
          }
        `}</script>
      </Helmet>
            <section >
              {/* <p className='landingpara'>Document your journey of what is yet to be done. From the moment you think of something till you do it. </p> */}
            {/* <p className='landingpara'>Document your journey with Dmyor. Add updates as you progress. Share it and release it when you are done.</p> */}
            {/* <p className='landingpara'>Write to your future with Dmyor. Add updates as you progress. Share it and release it when you are done.</p> */}
            {/* <p className='landingpara'>Write what is yet to be done with Dmyor. Add updates as you progress. Share it and release it when you are done.</p> */}
            <div className="homepage">
    <div className="landing-page">
      <div className="main-section">
        <h1 className='dmyor'>Dmyor</h1>
        <h1 className='opening-para'>
          Time Capsule your  {' '}
          <br />
          <span className='writer'>
            <TypeWriter data={words} />
          </span>
        </h1>
        <p className='motto'>Preserve and immortalize your experiences and thoughts as you bring the world in your mind to the real one.</p>
        <Link className='new-node-btn' to="/s">
        <button className='btn btn-orange btn-explore'>Explore</button>
          </Link>
        
      </div>
      <div className="video-container">
        <video autoPlay loop muted>
          <source src="/images/homepageVideo.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    </div>
            </section>
            <div className="row main-2">
            <div className="col-md-7 col-12 leftside">
                <img src='/images/home-2.png' alt="" className='home-image-1' />
            </div>
            <div className="col-md-5 col-12 rightside">
                <h2 className='para-1'>Create a node</h2>
                {/* <p>A node is message you write to your future self. Consider it as planting a node to your future waiting to be released. It is anonymous so the message can be viewed by anyone but creator's identity will not be provided.</p> */}
                {/* <p>Node is what is yet to be done written by you. Document what you are doing or what you want to do someday. By default, a node is anonymous. Your identity is made public after you release it. You can also make it private so only you can see your node.</p> */}
                <p>Think of your Node as your personal time capsule – In each node, you can store thoughts, goals, vision or journey that you would want to release one day.</p>
            </div>
            </div>
            <div className="row main-1">
            <div className="col-md-5 col-12 leftside">
            <h2 className='para-1'>Add Updates</h2>
                {/* <p>Once your node is created, now it's your job to make sure make your node a reality. You can add updates to add progress to your journey. Since you are doing something that requires will and would not happen if you don't make an effort, we call it Reality Bending.</p> */}
                <p>Once your node is created, you can also add updates. It can be the progress you made or your present thoughts on the node.</p>
            </div>
            <div className="col-md-7 col-12 rightside">
            <img src='/images/home-3.png' alt="" className='home-image-2' />
            </div>
            </div>
            <div className="row main-2">
            <div className="col-md-7 col-12 leftside">
            <img src='/images/home-4.png' alt="" className='home-image-1' />
            </div>
            <div className="col-md-5 col-12 rightside">.
                <h2 className='para-1'>Release Node</h2>
                {/* <p>After successfully bending your reality, now it's time to release the node. Releasing means the journey you started is over and you have finally reached your node. Releasing removes anonymity from your node and people can view creator's name with the node.</p> */}
                <p>Ready to share? Click "Release"! Your journey's done, and now you're putting your node on display. No more updates, just your story and your name. Time to spill the beans or keep the mystery – it's in your hands!</p>
            </div>
            </div>
            <div className="main joinus">
              <p className='joinus-para'>Time capsule what you think today for eternity. Make your first node now, for free!</p>
              <br />
              <Link to="/login">
              <button className='btn btn-homepage'> Create Node</button>
              </Link>
            </div>
        
    </div>
    </>
    </animated.div>
  )
}

export default Home