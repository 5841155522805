import React from 'react';
import './VerticalNavbar.css';
import { Link, NavLink } from 'react-router-dom';

const VerticalNavbar = ({ onCreateClick }) => {

  return (
    <nav className="vertical-navbar">
      <div className="vertical-container">
      <Link to="/" className='brand-link'>
      <h1 className='brand'>DMYOR</h1>
      </Link>
      <ul className="navbar-nav-vn">
      <li className="nav-item-vn">
        <NavLink  to="new" className={({ isActive, isPending }) =>
    isPending ? "pending nav-link-vn" : isActive ? "active nav-link-vn" : "nav-link-vn"
  }  onClick={onCreateClick}>
        <i className='bx bx-plus app-icons'></i>
        New Node
        </NavLink>
        </li>
        <li className="nav-item-vn">
        <NavLink  to="/dashboard" end className={({ isActive, isPending }) =>
    isPending ? "pending nav-link-vn" : isActive ? "active nav-link-vn" : "nav-link-vn"
  } >
        <i className='bx bx-notepad app-icons'></i>
        My nodes
        </NavLink>
        </li>
        <li className="nav-item-vn">
        <NavLink  to="search" className={({ isActive, isPending }) =>
    isPending ? "pending nav-link-vn" : isActive ? "active nav-link-vn" : "nav-link-vn"
  } >
        <i className='bx bx-search app-icons'></i>
        Explore
        </NavLink>
        </li>
        <li className="nav-item-vn">
        <NavLink  to="saved" className={({ isActive, isPending }) =>
    isPending ? "pending nav-link-vn" : isActive ? "active nav-link-vn" : "nav-link-vn"
  }>
        <i className='bx bx-bookmark app-icons'></i>
        Saved
        </NavLink>
        </li>
        <li className="nav-item-vn">
        <NavLink  to="account" className={({ isActive, isPending }) =>
    isPending ? "pending nav-link-vn" : isActive ? "active nav-link-vn" : "nav-link-vn"
  } >
        <i className='bx bxs-user app-icons'></i>
        Account
        </NavLink>
        </li>
      </ul>
      </div>
    </nav>
  );
};

export default VerticalNavbar;
