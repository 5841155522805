import React from 'react';
import './Privacy.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
const Privacy = () => {
  return (
    <div className='privacy-policy'>
       <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Learn how Dmyor uses user data." />
        <meta property="og:title" content="Dmyor Privacy Policy - Protecting Your Information" />
        <meta property="og:description" content="Learn how Dmyor uses user data." />
        <meta property="og:image" content="https://res.cloudinary.com/dkgg6mdtb/image/upload/v1695966172/android-icon-144x144_ildr9u.png" />
        <meta property="og:url" content="https://www.dmyor.com/privacy-policy" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Link className='note-link' to="/">
        <p className='refund-p'><i class='bx bxs-home-alt-2'></i>Home</p>
        </Link>
      <h1>Privacy Policy</h1>
      <p>
        <em>
      Last Updated: 20-12-2023
      </em>
      <br />
      Welcome to Dmyor! This Privacy Policy explains how we gather, employ, and safeguard your personal information as you use our website and services. By accessing or utilizing Dmyor, you agree to the terms laid out in this Privacy Policy.
      </p>
      <h2>
      1. Information We Collect
      </h2>
      <p>
During the process of registering an account, we gather specific personal details:

We collect your Name to provide a personalized experience and address you properly.

Your Date of Birth (DOB) helps us ensure that you meet the age criteria for using our services.

The Email Address you provide enables us to communicate essential information about your account and our services.
        </p>

      <h2>
      2. Purpose of Information Collection
      </h2>
      <p>
The personal information we collect serves to enrich your experience on Dmyor. More specifically, we employ this information to populate the "My Account" section of our website, granting you access to personalized content and features.
      </p>
      <h2>
      3. Information Usage
      </h2>
      <p>
Rest assured that we treat your personal information with the utmost care and respect. Under no circumstances do we share your personal information with third parties. Furthermore, we refrain from engaging in any marketing activities or behavioral analysis using the collected data.
        </p>
        <h2>
        4. Security Measures
        </h2>
        <p>
Ensuring the security of your personal information is of paramount importance. While we implement reasonable security measures to protect your data from unauthorized access and breaches, it's important to acknowledge that the internet is not entirely devoid of security risks.
        </p>
        <h2>
        5. Data Retention
        </h2>
        <p>
We retain your personal information as long as you maintain an active account on our platform. If you choose to deactivate your account, your personal information will be securely deleted from our systems.
        </p>
        <h2>
        6. User Rights
        </h2>
        <p>
Our belief in putting you in control of your personal information is reflected in the following user rights:

Access: You can access the personal information we have about you by logging into your account.

Modify: Should your personal information change, you have the ability to update it within the "My Account" section.

Delete: At any point, you can choose to delete your account and all associated personal information.
    </p>
    <h2>
    7. Contact Information
    </h2>
    <p>
For any inquiries, concerns, or requests regarding your privacy, please do not hesitate to contact us at <a href="mailto:support@dmyor.com">support@dmyor.com</a>. We are committed to addressing your queries in a timely and transparent manner.
    </p>
    <h2>
    8. Changes to Privacy Policy
    </h2>
    <p>
This Privacy Policy may undergo updates periodically. We will inform you of substantial changes via email or by posting a notice on our website.

By using Dmyor, you signify your understanding and agreement with this Privacy Policy. If you do not agree with any aspect of this policy, please refrain from using our services.

For further questions or concerns regarding our privacy practices, please reach out to us at <a href="mailto:support@dmyor.com">support@dmyor.com</a>.
    </p>
    </div>
  )
}

export default Privacy