import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchPost } from '../../actions/postAction';
import './Search.css';
import SearchResults from '../UserPortal/SearchResults';
import Loader from '../../layout/Loder';
import { Helmet } from 'react-helmet';
import LittleLoader from '../../layout/LittleLoader';
import { useNavigate } from 'react-router-dom';

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const dispatch = useDispatch();
  const [loadBtn, setloadBtn] = useState(false);
  const {loading} = useSelector((state)=> state.search);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };


  const handleSearch = async (searchdata, page) => {
    // console.log(searchTerm);
    // console.log(currentPage);
    if(searchTerm.toLocaleLowerCase() == "rules"){
      navigate("/dashboard/note-details/659050837d7afe581f016f06");
      return;
    }
    const data = await dispatch(searchPost(searchTerm, page)); // Pass selectedField to the searchPost action
    // console.log(data.posts);
    var pages = Math.floor(data.postCount/12);
    if(data.postCount%12>0)pages++;
    // console.log(currentPage);
    // console.log(pages);
    setTotalPages(pages);
    // console.log(searchResults);
    // console.log(data);
    if(searchdata =="newsearch"){
      setSearchResults(data.posts);
    }else{
    setSearchResults([...searchResults, ...data.posts]);
    }
    // console.log(data.posts.length%12);
    if(data.posts.length%12!==0){
      setloadBtn(false);
    }else{
      setloadBtn(true);
    }
    // console.log(currentPage);
    // console.log(searchResults);
  };
  const newSearch = async() =>{
    setCurrentPage(1);
    setSearchResults([]);
    // console.log(searchResults);
    await handleSearch("newsearch");
  }
  const goToPage = (page) => {
    // console.log(page);
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      handleSearch("notnewsearch", page);
    }
  };
  useEffect(() => {
    handleSearch();
  }, []);
  return (
    <div className='search'>
      <Helmet>
        <title>Explore - Dmyor</title>
        <meta name="description" content="Explore search results on Dmyor"/>
        <meta name="robots" content="index, follow" /> {/* Optional: Control indexing behavior */}
        <link rel="canonical" href="https://www.dmyor.com/s" /> {/* Optional: Specify the canonical URL */}
      </Helmet>
      <div className='searchoptions'>
      <input
        type="text"
        id = "searchbar"
        value={searchTerm}
        onChange={handleChange}
        placeholder="Search 'RULES'"
      />
      <button className='btn search-btn' onClick={newSearch}>Search</button>
      </div>
      {
        searchResults.length > 0 ? (
          <div>
            <div className="saved row">
              {searchResults.map((post) => (
                <div key={post._id} className="single-note col-lg-4 col-md-6 col-sm-12">
                  <SearchResults key={post._id} post={post} />
                </div>
              ))}
            </div>
                
          </div>
        ) : loading?<Loader/>:(
          <div className="nomatch">
            <p>No match found.</p>
          </div>
        )
        
      
      }

      {loading? <LittleLoader/>:(
        <>
        {loadBtn?(
          <div className="pagination-controls loadbtn">
        <button className="btn btn-orange" onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>
          Load more
        </button>
        </div>
        ):(
          <div className='loadbtnnotavail'></div>
        )}
        </>
      )}
    </div>
  );
};

export default Search;
