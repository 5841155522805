import {
    LOGIN_REQUEST,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    ALL_USERS_REQUEST,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    DECREASE_TOKENS_REQUEST,
    DECREASE_TOKENS_SUCCESS,
    DECREASE_TOKENS_FAIL,
    CLEAR_ERRORS,
  } from "../constants/userConstants";
  import axios from "axios";
  import {toast} from 'react-toastify';
import config from "../config";
  
  const serverurl = config.apiUrl;
  const serverConfig = {
    withCredentials:true,
}
  // Login
  export const login = (email, password) => async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const config = { headers: { "Content-Type": "application/json" }};
      const { data } = await axios.post(
        `${serverurl}/api/v1/login`,
        { email, password },
        serverConfig
      );
      // console.log(data);
      dispatch({ type: LOGIN_SUCCESS, payload: data.user });
    } catch (error) {
      // console.log(error);
      dispatch({ type: LOGIN_FAIL, payload: error.response.data.message });
      toast.error(error.response.data.message);
    }
  };

  export const loginWithGoogle = () => async(dispatch) => {
    try{
      // console.log(serverurl);
      window.open(`${serverurl}/api/v1/auth/google`, "_self");
    }catch(error){
      dispatch({ type: LOGIN_FAIL, payload: error.response.data.message });
      toast.error(error.response.statusText);
    } 
  }
  // Register
  export const register = (userData) => async (dispatch) => {
    try {
      dispatch({ type: REGISTER_USER_REQUEST });
      const { data } = await axios.post(`${serverurl}/api/v1/register`,  userData, serverConfig);
      // console.log("sent req", data);
      dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: error.response.data.message,
      });
      toast.error(error.response.data.message);
    }
  };

  // Load User
  export const loadUser = () => async (dispatch) => {
    try {
      dispatch({ type: LOAD_USER_REQUEST });
      
      const config = { withCredentials: true};
      const { data } = await axios.get(`${serverurl}/api/v1/me`, serverConfig);
      dispatch({ type: LOAD_USER_SUCCESS, payload: data.user });
    } catch (error) {
      dispatch({ type: LOAD_USER_FAIL, payload: "Something went wrong" });
      // toast.error("Something went wrong. Please try again");
    }
  };

  //decrease token
  
  export const decreasetokens = () => async(dispatch) => {
    try{
        dispatch({type: DECREASE_TOKENS_REQUEST});
        dispatch({type: DECREASE_TOKENS_SUCCESS});
    }catch(error){
        dispatch({type: DECREASE_TOKENS_FAIL});
        toast.error(error.response.statusText);
    }
  }

  // Logout User
  export const logout = () => async (dispatch) => {
    try {
      await axios.post(`${serverurl}/api/v1/logout`, serverConfig);
      dispatch({ type: LOGOUT_SUCCESS });
    } catch (error) {
      dispatch({ type: LOGOUT_FAIL, payload: error.response.data.message });
      toast.error(error.response.statusText);
    }
  };
  
  // Update Profile
  export const updateProfile = (userData) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PROFILE_REQUEST });
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.put(`${serverurl}/api/v1/me/update`, userData, serverConfig);
      dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({
        type: UPDATE_PROFILE_FAIL,
        payload: error.response.data.message,
      });
      toast.error(error.response.statusText);
    }
  };
  
  // Update Password
  export const updatePassword = (passwords) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PASSWORD_REQUEST });
      const config = { headers: { withCredentials: true }};
      const { data } = await axios.put(
        `${serverurl}/api/v1/password/update`,
        passwords,
        serverConfig
      );
      dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({
        type: UPDATE_PASSWORD_FAIL,
        payload: error.response.data.message,
      });
      toast.error(error.response.statusText);
    }
  };
  
  // Forgot Password
  export const forgotPassword = (email) => async (dispatch) => {
    try {
      dispatch({ type: FORGOT_PASSWORD_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.post(`${serverurl}/api/v1/password/forgot`, email, serverConfig);
      dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data.message });
    } catch (error) {
      dispatch({
        type: FORGOT_PASSWORD_FAIL,
        payload: error.response.data.message,
      });
      toast.error(error.response.statusText);
    }
  };
  
  // Reset Password
  export const resetPassword = (token, passwords) => async (dispatch) => {
    try {
      dispatch({ type: RESET_PASSWORD_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.put(
        `${serverurl}/api/v1/password/reset/${token}`,
        passwords,
        serverConfig
      );
      dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: error.response.data.message,
      });
      toast.error(error.response.statusText);
    }
  };
  
  // get All Users
  export const getAllUsers = () => async (dispatch) => {
    try {
      dispatch({ type: ALL_USERS_REQUEST });
      const { data } = await axios.get(`${serverurl}/api/v1/admin/users`, serverConfig);
      dispatch({ type: ALL_USERS_SUCCESS, payload: data.users });
    } catch (error) {
      dispatch({ type: ALL_USERS_FAIL, payload: error.response.data.message });
      toast.error(error.response.statusText);
    }
  };
  
  // get  User Details
  export const getUserDetails = (id) => async (dispatch) => {
    try {
      dispatch({ type: USER_DETAILS_REQUEST });
      const { data } = await axios.get(`${serverurl}/api/v1/admin/user/${id}`, serverConfig);
      dispatch({ type: USER_DETAILS_SUCCESS, payload: data.user });
    } catch (error) {
      dispatch({ type: USER_DETAILS_FAIL, payload: error.response.data.message });
      toast.error(error.response.statusText);
    }
  };
  
  // Update User
  export const updateUser = (id, userData) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.put(
        `${serverurl}/api/v1/admin/user/${id}`,
        userData,
        serverConfig
      );
      dispatch({ type: UPDATE_USER_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAIL,
        payload: error.response.data.message,
      });
      toast.error(error.response.statusText);
    }
  };
  
  // Delete User
  export const deleteUser = (id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_USER_REQUEST });
      const { data } = await axios.delete(`${serverurl}/api/v1/admin/user/${id}`,serverConfig);
      dispatch({ type: DELETE_USER_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_USER_FAIL,
        payload: error.response.data.message,
      });
      toast.error(error.response.statusText);
    }
  };
  
  // Clearing Errors
  export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };
  