import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './Note.css'; // You can create this CSS file to add your custom styles
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Release from './Release';
import Sharelink from './Sharelink';
import formatDate from '../../util';


function Note({post}) {
  const {user} = useSelector((state) => state.user);
  const [showPopup, setShowPopup] = useState(false);
  const [showsharelink, setshowsharelink] = useState(false);

  const handleRelease = () => {
    setShowPopup(true);
    document.body.style.overflow = 'hidden';
  };

  const shareLink = (e) => {
    e.preventDefault();
    setshowsharelink(!showsharelink);
  }
  const statusTooltip = (
    <Tooltip id="status-tooltip">{(post.status == "released")?"Released":(post.status == "active")?"Active":"Standby"}</Tooltip>
  );
  const createdTooltip = (
    <Tooltip id="created-tooltip">Created on</Tooltip>
  );
  // const formattedDate = moment(post.date).format('DD-MM-yyyy');
  const inputDate = new Date(post.date);
  const formattedDate = formatDate(inputDate);
  return (
    <div className="note ">
      <div className="view-fullpage">
        {(post.method == 'digicoins')? (
          <i class='bx bx-share-alt share-icon' onClick={(e)=>shareLink(e)}></i>
        ):(
          <></>
        )}
      </div>
      <OverlayTrigger placement="top" overlay={statusTooltip}>
      <div className={` ${post.status==="released" ? "status-released" :post.status==="active"? "status-active":"status-standby"}`}></div>
      </OverlayTrigger>
      <Link to={`/dashboard/note-details/${post._id}`} className='note-link'>
      <div className="description">
      
      <p id="text" className='content'>{post.note.substring(0, 150) + '...'}</p>
      </div>
      </Link>

      <div className="note-bottom">
      <OverlayTrigger placement="top" overlay={createdTooltip}>
        <p className='create-date'>{formattedDate}</p>
        </OverlayTrigger>
        {
          
          (post.status==="released")?(
            <button className='btn btn-green' disabled={true}>Released</button>
          ):(
            <button className='btn btn-transornage' onClick={handleRelease}>{post.method=="digicoins"?"Release":"Change status"}</button>
          )
        }
        
      </div>
    
      {showPopup && (
          <div className="overlay">
            <i class='bx bxs-x-circle'></i>
            <Release setShowPopup={setShowPopup} post = {post} user={user}/>
          </div>
        
      )}
      {showsharelink &&(
        <div className="overlay">
            <Sharelink setshowsharelink={setshowsharelink}  postId={post._id}/>
        </div>
      )}
    </div>
  );
}

export default Note;