import axios from "axios";
import {
    STATS_REQUEST,
    STATS_SUCCESS,
    STATS_FAIL,
    FETCH_PRICE_REQUEST,
    FETCH_PRICE_SUCCESS,
    FETCH_PRICE_FAIL,
    CHANGE_PRICE_SUCCESS
} from "../constants/adminConstants"
import {toast} from 'react-toastify';
import config from "../config";

const serverurl = config.apiUrl;
const serverConfig = {
    withCredentials:true,
}

export const getStats = () => async(dispatch) => {
    try{
        dispatch({type: STATS_REQUEST})
        const {data:{totalPosts}}= await axios.get(`${serverurl}/api/v1/admin/getstats`, serverConfig);
        dispatch({type: STATS_SUCCESS, payload:totalPosts});
    }catch(error){
        dispatch({type:STATS_FAIL});
        toast.error(error.response.statusText);
    }
}
export const getPrice = () => async(dispatch) => {
    try{
        dispatch({type: FETCH_PRICE_REQUEST });
        const {data:{price}} = await axios.get(`${serverurl}/api/v1/getprice`);
        dispatch({type:FETCH_PRICE_SUCCESS, payload: price});
    }catch(error){
        toast.error(error.response.statusText);        
    }
}
export const changePrice = (data) => async(dispatch) => {
    try{
        const {data:{price}} = await axios.post(`${serverurl}/api/v1/admin/changeprice`,data,serverConfig);
        dispatch({type:CHANGE_PRICE_SUCCESS,payload: price})
    }catch(error){
        toast.error(error.response.statusText);        
    }
}