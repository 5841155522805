import React from 'react'
import { Link, Outlet } from 'react-router-dom';
import './Navbar.css'
import Footer from '../layout/Footer';



const Navbar = () => {
return (
<>
    <nav className="navbar navbar-expand-lg bg-color navbar-dark custom-navbar">
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
    </button>
        <a className="navbar-brand" href="/">DMYOR</a>
        <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <Link className='new-node-btn' to="/">
                        <p className='nav-link text-white'>Home</p>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className='new-node-btn' to="/about">
                    <p className="nav-link text-white">About</p>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className='new-node-btn' to="/s">
                    <p className="nav-link text-white">Explore</p>
                    </Link>
                </li>
                {/* <li className="nav-item">
                    <Link className='new-node-btn' to="/pricing">
                    <p className="nav-link text-white">Pricing</p>
                    </Link>
                </li> */}
                
            </ul>
        </div>
        <div className="buttons">
        <Link to="login">
        <button className='btn btn-transparent signin-btn-navbar'>Log In</button>
        </Link>
        <Link to='signup'>
        <button className='btn btn-transparent signup-btn-navbar' >Sign Up</button>
        </Link>
        </div>
    </nav>
    
    <div className="outlet">
        <Outlet/>
    </div>
    <div className="footer">
        <Footer/>
    </div>
</>
)
}

export default Navbar