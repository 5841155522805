import axios from 'axios';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import config from "../../config";
import './ResetPassword.css';

const serverurl = config.apiUrl;
const serverConfig = {
    withCredentials:true,
}
const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [btnloading, setbtnloading] = useState(false);
  const [passwordreset, setpasswordreset] = useState(false);

  const validatePassword = (value) => {
    if (!value) {
      return 'Password is required';
    } else if (value.length < 8) {
      return 'Password must be at least 8 characters long';
    }
    return '';
  };
  
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setMessage('');
    setErrors((prevErrors) => ({ ...prevErrors, password: validatePassword(value) }));
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setbtnloading(true);

    if (password === confirmPassword) {
      const data = {
        password: password,
        confirmPassword: confirmPassword,
      };

      try {
        const res = await axios.put(`${serverurl}/api/v1/password/reset/${token}`, data);
        // console.log(res);
        toast.success('Password updated');
        setPassword('');
        setConfirmPassword('');
        setpasswordreset(true);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    } else {
      setMessage('Passwords do not match');
    }
    setbtnloading(false);
  };

  return (
    <div className="card-container">
      <div className="reset-card">
        <h1>Reset Password</h1>
        {passwordreset?(
         <>
         <p>Password reset Successful!</p>
         </>
        ):(
        <>
        <form className='reset-form'>
          <div className="">
            <label>New Password</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              required
              className="form-control"
            />
            {errors.password ? (<p className="signup-error-text">{errors.password}</p>) : (<p></p>)}
          </div>
          <div className="">
            <label>Confirm Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
              className="form-control"
            />
          </div>
          {message && <p className='password-reset-alert'>{message}</p>}
          </form>
          {btnloading?(
            <button className= "pay-btn btn-loading">
            </button>
          ):(
            <button 
            className= "pay-btn btn-reset"
            onClick={handleSubmit} 
            >
            Reset password
            </button>
          )}
        </>
        
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
