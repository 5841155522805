import React from "react";
import "./LittleLoader.css";

const LittleLoader = () => {
  return (
    <div className="littleloading">
      <div></div>
    </div>
  );
};

export default LittleLoader;
