import {
    SEARCH_POST_REQUEST,
    SEARCH_POST_SUCCESS,
    SEARCH_POST_FAIL,
    CLEAR_ERRORS
} from '../constants/searchConstants';

export const searchReducer = (state = {loading: false}, action) => {
    switch(action.type){
        case SEARCH_POST_REQUEST:
            return {
                loading: true,
            };
        case SEARCH_POST_SUCCESS:{
            return {
                loading: false,
            }
        }
        case SEARCH_POST_FAIL:{
            return {
                loading: false,
            }
        }
        case CLEAR_ERRORS:
                return {
                  ...state,
                  error: null,
                };
          
        default:
                return state;
            }

    }
