import React, {useState, useEffect} from 'react';
import { useNavigate} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import './NewNote.css';
import { createPost } from '../../actions/postAction';
import { decreasetokens } from '../../actions/userAction';
import { toast } from 'react-toastify';
import {Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TokenPopup from './TokenPopup';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Notification from '../Utility/Notification';

const NewNote = () => {
    const{user} = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [tokenCount, setTokenCount] = useState(1);
    const [content, setContent] = useState('');
    const [selectedOption, setSelectedOption] = useState('digicoins');
    const [btnloading, setbtnloading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showNoti, setshowNoti] = useState(false);
  
    const handleNext = () => {
        setStep(step + 1);
      };
      const handleBack = () => {
        (step>1)?setStep(step-1):setStep(step);
      }
      const handlePost = async (e) => {
        e.preventDefault();
        
          if(selectedOption == "digicoins" && user.tokens == '0'){
            toast.error("You have insufficient D-credits. Please buy credits to post.",
            {autoClose: false}
            );
            return;
          }
          const data = {note:content, method:selectedOption};
          try{
            setbtnloading(true);
          const res = await dispatch(createPost(data));
          if(res && selectedOption == "digicoins"){
          await dispatch(decreasetokens());
          }
          navigate(`/dashboard/note-details/${res._id}`);
          
          }catch(error){
            toast.error(error);
          }finally{
            setbtnloading(false);
          }
          
          
      };

      const handleChangeContent = (e) => {
        setContent(e.target.value);
      };

      const handleChange = (e) => {
        setSelectedOption(e.target.value);
      };
      const updateTokenCount = (newCount) => {
        setTokenCount(newCount);
      };
      const closePopup = () => {
        setShowPopup(false);
      };

      const visibilityTooltip = (
        <Tooltip id="visibility-tooltip">You can make your log public or private</Tooltip>
      );
      const updateTooltip = (
        <Tooltip id="update-tooltip">You can add updates to log that describes your journey</Tooltip>
      );
      const releaseTooltip = (
        <Tooltip id="release-tooltip">Releasing log will display your name along with log.</Tooltip>
      );
      const statusTooltip = (
        <Tooltip id="status-tooltip">You can change status from active to released.</Tooltip>
      );

      useEffect(() => {
       if(user.country && user.country!=="IN"){
        setshowNoti(true);
       }
      }, [])
      
    
      const renderContentSection = (
        <div className='node-input'>
        <h2 className='quote'>Write what you want your future self to see</h2>
        <form className='note-form'> 
          <div className="form-group">
            <textarea
              id="mytextarea"
              readOnly={false}
              value={content}
              onChange={handleChangeContent}
              placeholder='I am building a time machine...'
              name="content"/>
          </div>
          <div className="formbtn">
          <button
          className='btn btn-next'
          type="submit"
          onClick={handleNext}
          disabled={content.length === 0} // Disable the button if content length is 0
        >
          Next
        </button>
          </div>
        </form>
        </div>
      );

  const typeOfNote = (
<>
{
      user.tokens === 0 && showNoti && (
        <Notification notification = "If payment is not avaiable in your country, you can create a basic log for now. You will be given option to convert to D-credit log soon." setshowNoti={setshowNoti}/>
      )
    }
  <div className='note-type'>
    <div className="method-radio-container row">
      <div className={`card ${selectedOption =="digicoins"?'selected':''} col-md-6 mb-12`}>
        <label className="radio-label">
          <input
            type="radio"
            value="digicoins"
            checked={selectedOption === 'digicoins'}
            onChange={handleChange}
          />
          <div className="card-content">
            <h3>D-credit ({user.tokens})</h3>
            <ul className='no-bullets-list'>
              <li>
                <i class='bx bx-check app-icons'></i>
                Create Log
               
              </li>
              <li>
                <i class='bx bx-check app-icons'></i>
               Add Updates <OverlayTrigger placement="top" overlay={updateTooltip}>
                 <i class='bx bx-info-circle'></i>
                </OverlayTrigger>
              </li>
              <li>
                <i class='bx bx-check app-icons'></i>
                Visibility controls <OverlayTrigger placement="top" overlay={visibilityTooltip}>
                 <i class='bx bx-info-circle'></i>
                </OverlayTrigger>
              </li>
              <li>
                <i class='bx bx-check app-icons'></i>
                Release <OverlayTrigger placement="top" overlay={releaseTooltip}>
                 <i class='bx bx-info-circle'></i>
                </OverlayTrigger>
              </li>
            </ul>
            <button className='btn btn-orange' onClick={() => setShowPopup(!showPopup)}>Buy Credits</button>
          </div>
        </label>
      </div>

      {/* {(user.country && user.country!=="India")?
        (
        <div className={`card ${selectedOption =="hybrid"?'selected':''} col-md-6 mb-3`}>
        <label className="radio-label">
          <input
            type="radio"
            value="hybrid"
            checked={selectedOption === 'hybrid'}
            onChange={handleChange}
          />
          <div className="card-content">
            
            <p>Can't pay because payment provider isn't on your location? Use premium node now and pay when payment is available in your location.</p>
          </div>
        </label>
      </div>
      ):(<></>)} */}
      
      <div className={`card ${selectedOption =="free"?'selected':''} col-md-6 mb-12`}>
        <label className="radio-label">
          <input
            type="radio"
            value="free"
            checked={selectedOption === 'free'}
            onChange={handleChange}
          />
          <div className="card-content">
            <h3>Basic</h3>
            <ul className='no-bullets-list'>
              <li>
                <i class='bx bx-check app-icons'></i>
                Create Log
              </li>
              <li>
                <i class='bx bx-check app-icons'></i>
                Change status <OverlayTrigger placement="top" overlay={statusTooltip}>
                 <i class='bx bx-info-circle'></i>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
        </label>
      </div>
      
      
    </div>
    {selectedOption === 'free' && (
      <p style={{ color: 'red', fontSize: '12px' }}>
        You won't be able to release the log.
      </p>
    )}
    <div className="post-navigation">
      <button className='btn btn-next' onClick={handleBack}>Back</button>
      {btnloading?(
            <button disabled={true} className= "btn btn-post">
              Posting...
            </button>
          ):(
            <button onClick={handlePost} className='btn btn-post'>Post</button>
          )}
      {/* <button onClick={handlePost} className='btn btn-post'>Post</button> */}
    </div>
    
  </div>
</>

      );
      
  return (
      <div className="modal-content">
        <Helmet>
        <title>New Log</title>
      </Helmet>
        {step === 1 && renderContentSection}
        {step === 2 && typeOfNote}
        {showPopup && (
                <TokenPopup tokenCount={tokenCount} updateTokenCount={updateTokenCount} closePopup={closePopup} />
              )}
              
      </div>
  );
};

export default NewNote;
