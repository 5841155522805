import React, { useEffect, useState } from 'react'
import './Prices.css';
import ChangePrices from './ChangePrices';
import { useDispatch, useSelector } from 'react-redux';
import { getPrice } from '../../actions/adminAction';
import Loader from '../../layout/Loder';
const Prices = () => {
    const [showpricespopup, setshowpricespopup] = useState(false);
    const {loading, price} = useSelector((state) => state.stats);
    const dispatch = useDispatch();
    const handleClick = () =>{
        setshowpricespopup(!showpricespopup);
    }

    useEffect(() => {
        dispatch(getPrice());
    }, [])
    
  return (
    <>
    {loading? (
        <Loader/>
    ):(
    <div className='prices'>
        <h1>Prices</h1>
        <p>Current price: {price.price}</p>
        <button
         className="btn btn-orange" 
         onClick={handleClick}
         >
            Change price
        </button>
    </div>
    )}
    {showpricespopup &&(
        <div className="overlay">
            <ChangePrices handleClick={handleClick}/>
        </div>
    )
    }
    
    </>
  )
}

export default Prices