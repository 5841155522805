import {
    STATS_REQUEST,
    STATS_SUCCESS,
    STATS_FAIL,
    FETCH_PRICE_REQUEST,
    FETCH_PRICE_SUCCESS,
    FETCH_PRICE_FAIL,
    CHANGE_PRICE_SUCCESS,
    CLEAR_ERRORS
} from "../constants/adminConstants"



export const adminReducer = (state = {totalPosts:0, price:0}, action) => {
    switch(action.type){
        case STATS_REQUEST:
            return{
                ...state,
                loading:true,
                totalPosts:0,
            }
        case STATS_SUCCESS:
            return{
                ...state,
                loading: false,
                totalPosts:action.payload,
            }
        case STATS_FAIL:
            return{
                loading:false,
                error:action.payload,
            }
        case FETCH_PRICE_REQUEST:
            return{
                ...state,
                loading:true,
                price:0
            }
        case FETCH_PRICE_SUCCESS:
            return{
                ...state,
                loading:false,
                price: action.payload,
            }
            case FETCH_PRICE_FAIL:
                return{
                    loading:false,
                    error:action.payload,
                }
            case CHANGE_PRICE_SUCCESS:
                return{
                    ...state,
                    price: action.payload,
                }
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null,
            }
        default:
            return state;
        
    }
}