import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {toast} from 'react-toastify';
import './NoteDetails.css';
import { changeStatus, savePost } from '../../actions/postAction';
import Loader from '../../layout/Loder';
import Settingspopup from './Settingspopup';
import Sharelink from './Sharelink';
import Release from './Release';
import UpdateMessage from './UpdateMessage';
import NewUpdate from './NewUpdate';
import config from '../../config';
import { Helmet } from 'react-helmet';
import formatDate from '../../util';

const NoteDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {user} = useSelector((state) => state.user);
  const {postids} = useSelector((state) => state.savedposts);
  const {id} = useParams();
  const [post, setpost] = useState('');
  const [date, setdate] = useState();
  const [releasedate, setreleasedate] = useState();
  const [showsettings, setshowsettings] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showsharelink, setshowsharelink] = useState(false);
  const [dateString, setdateString] = useState();
  const [maxDate, setmaxDate] = useState();
  const [saved, setsaved] = useState(postids.includes(id));
  const handleRelease = () => {
    setShowPopup(true);
    document.body.style.overflow = 'hidden';
  };

  const handleSettings = (e) => {
    e.preventDefault();
    setshowsettings(!showsettings);
  }
  const handleUpdate = (e) =>{
    e.preventDefault();
    setShowUpdate(!showUpdate);
  }
  const closeSettings = (e) => {
    e.preventDefault();
    setshowsettings(!showsettings);
  }

  const shareLink = (e) => {
    e.preventDefault();
    setshowsharelink(!showsharelink);
  }

  const shareTooltip = (
    <Tooltip id="share-tooltip">Share</Tooltip>
  );
  const createdbyTooltip = (
    <Tooltip id="share-tooltip">Created by</Tooltip>
  );
  const ttcTooltip = (
    <Tooltip id="share-tooltip">Time to release</Tooltip>
  );
  const saveTooltip = (
    <Tooltip id="share-tooltip">{(postids.includes(id))?"Unsave":"Save"}</Tooltip>
  );

  const saveunsave = async(e) => {
    e.preventDefault();
    setsaved(!saved);
    const element = e.target;
    const classToToggle = 'post-saved';
    if (element.classList.contains(classToToggle)) {
      element.classList.remove(classToToggle);
    } else {
      element.classList.add(classToToggle);
    }
    const data = {
      postId: id,
    }
    try{
    await dispatch(savePost(data));
    }catch(error){
      toast.error(error);
    }
  }

  useEffect(() => {
      const fetchPost = async() => {
        const serverurl = config.apiUrl;
        try{
          const {data} = await axios.get(`${serverurl}/api/v1/myposts/${id}`, {withCredentials:true});
          setpost(data.post);
          const thisdate = new Date(data.post.date);
          const dateString= formatDate(thisdate);
          setdate(dateString);
          if(data.post.status == 'released'){
            const thisdate = new Date(data.post.releaseDate);
            const dateString= formatDate(thisdate);
            setreleasedate(dateString);
          }
        }catch(error){
          toast.error(error.response.data.message);
          navigate("/mynotes");
        }
        
        
      }
      fetchPost();
  }, []);
  
  


  return (
    <>
    <Helmet>
        <title>{`Node - ${post._id}`}</title>
        <meta name="description" content={post.note} />
        <meta property="og:title" content="See this Node" />
        <meta property="og:description" content={post.note} />
        <meta property="og:url" content={`https://www.dmyor.com/dashboard/note-details/${post._id}`} />
        <meta property="og:type" content="website" />
      </Helmet>
    {(post)? (
      <div className="note-details">
      <div className="content">
        <div className="details-header">
          <div className='options-left'>
            {(post.status === "released")?(
              <div>
              <OverlayTrigger placement="top" overlay={createdbyTooltip}>
              {post.username? <p><span className=''> <i class='bx bxs-user options-left-icons' ></i> {post.username}</span></p>: <></>}
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={ttcTooltip}>
              {post.totalTime?<p><span className=''><i class='bx bx-time options-left-icons'></i> {post.totalTime}</span></p>:''}
              </OverlayTrigger>
              </div>
            ):(
              <></>
            )}
        
        </div>
        <div className='options'>
          {(post.method === "digicoins" )?(
             <OverlayTrigger placement="top" overlay={shareTooltip}>
             <i className="bx bx-share-alt detailshare options-right-icons" onClick={shareLink}></i>
           </OverlayTrigger>
          ):(
            <>
            </>
          )}
          {
            (post.user.toString() !== user._id.toString())?(
              <OverlayTrigger placement="top" overlay={saveTooltip}>
              <i 
              className={`bx ${saved ? 'post-saved bxs-bookmark options-right-icons' : 'bx-bookmark options-right-icons'}`}
              onClick={(e)=>saveunsave(e)}
              >
              </i>
              </OverlayTrigger>
            ):(<>
            </>)
          }
          
        {(post.user.toString() === user._id.toString())?(
        <i class='bx bx-cog options-right-icons' onClick={(e) => handleSettings(e)} ></i>
        ):(<></>)}
        </div>
        </div>
        <p>{dateString}</p>
        <div className="desc">
        {(post.user.toString() === user._id.toString())?(
        <i class='bx bx-cog setting-mobile' onClick={(e) => handleSettings(e)} ></i>
        ):(<></>)}
        {
          post.note.split('\n').map((line, index) => (
            <p key={index}>
              {line}
              {/* <br /> */}
            </p>
          ))
        }
        </div>
        <div className="status-bar">
          <div className="share-mobile">
          {
            (post.user.toString() !== user._id.toString())?(
              <OverlayTrigger placement="top" overlay={saveTooltip}>
              <i 
              className={`bx ${saved ? 'post-saved bxs-bookmark' : 'bx-bookmark'}`}
              onClick={(e)=>saveunsave(e)}
              >
              </i>
              </OverlayTrigger>
            ):(<>
            </>)
          }
        {(post.method === "digicoins" )?(
             <OverlayTrigger placement="top" overlay={shareTooltip}>
             <i className="bx bx-share-alt detailshare" onClick={shareLink}></i>
           </OverlayTrigger>
          ):(
            <>
            </>
          )}
        
      </div>
      <div className="flex-container">
        <div className="left">
        </div>
        <div className='right'>
        <p>{post.status =="released"?`${date} - ${releasedate}` : `${date}`}</p>
          {/* {post.username? <p><span className='release-span'>Created by: {post.username}</span></p>: <></>} */}
          <div className={post.status=="released" ? "released-dot" :(post.status=="active") ? "active-dot":"standby-dot"}></div>
          <p className={post.status=="released" ? "status-released-text" :(post.status=="active") ? "status-active-text":"status-standby-text"}> {post.status=="released"?"Released":(post.status=="active") ? "Active":"Standby"}</p>
          {/* {post.totalTime?<p>{post.totalTime}</p>:''} */}
        </div>
      </div>
          
        </div>
        <div className="extra-space"></div>
        <div className="updates">
          {post.updates && post.updates.length>0 ? (
            post.updates.map((update, index) => (
              <UpdateMessage note={update.update} date = {update.date} maxDate={maxDate} setmaxDate={setmaxDate}/>
            ))
          ):(<></>)}
          {post.releasenote ? (
          <div className="desc">
            <p className='releasenote'>
            {
              post.releasenote.split('\n').map((line, index) => (
                <p key={index}>
                  {line}
                  <br />
                </p>
              ))
            }
            </p>
        </div>
        ):(
        <></>
      )
        }
          </div>
        
        
      </div>
      <div className="fixed-bottom">
        {
          (post.user.toString() === user._id.toString())?(
      (post.status==="released")?(
            <button className='btn btn-bottom-release btn-green' disabled={true}>Released</button>
          ):(
            post.method == "digicoins"?(
            <>
            <button 
          className="btn btn-orange btn-bottom-release"
          onClick={(e) => handleUpdate(e)}
          disabled={post.status === "standby"}
          >Update</button>
            <button className='btn btn-bottom-release btn-fullgreen' onClick={handleRelease}>Release</button>
            </>):(
              <button className='btn btn-bottom-release btn-fullgreen' onClick={handleRelease}>Change Status</button>
            )
          )):(
            <></>
          )
        } 
        {/* <button className="btn btn-bottom-release">Release</button> */}
      </div>

      {showPopup && (
        <div className="overlay">
        <i class='bx bx-x' onClick={() => setShowPopup(false)}></i>
        <Release setShowPopup={setShowPopup} post = {post} setpost={setpost} setreleasedate={setreleasedate} user={user}/>
      </div>
      )}
      {
        showsettings && (         
          <Settingspopup closeSettings={closeSettings} post = {post} setpost={setpost}/>
        )
      }
      {showsharelink &&(
        <div className="overlay">
              <i class='bx bx-x' onClick={() => setshowsharelink(false)}></i>
            <Sharelink setshowsharelink={setshowsharelink}  postId={post._id}/>
        </div>
      )}
      {showUpdate &&(
        <div className="overlay">
              <i class='bx bx-x' onClick={() => setShowUpdate(false)}></i>
            <NewUpdate postId={post._id} setpost={setpost} setShowUpdate={setShowUpdate}/>
        </div>
      )}
    </div>
    ):(
      <>
      <Loader/>
      </>
    )}
    </>
  );
  
};

export default NoteDetails;
