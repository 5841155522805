import React from 'react';
import { Outlet } from 'react-router-dom';
import './Dashboard.css';
import VerticalNavbar from './VerticalNavbar';
import BottomNavbar from './BottomNavbar';

const DashBoard = ({ onCreateClick }) => {
  return (
  <div className="mynotes row">
  <div className="v-nav col-lg-3 col-md-3">
    <VerticalNavbar onCreateClick={onCreateClick} />
  </div>
  <div className="user-portal col-lg-9 col-md-9 col-12">
    <Outlet />
  </div>
  <div className="bottom-navbar d-md-none col-12">
    <BottomNavbar onCreateClick={onCreateClick} />
  </div>
</div>

  );
};

export default DashBoard;
