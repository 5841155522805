import React from 'react';
import './TokenPopup.css'
import { Link } from 'react-router-dom';

const TokenPopup = ({ tokenCount, updateTokenCount, closePopup }) => {
  return (
    <div className="popup-container">
      <div className="blur-background"></div>
      <div className="popup">
      <h2>Buy D-credits</h2>
      <div className='tokenhandler'>
        <button className='btn' onClick={() => updateTokenCount(tokenCount>5?tokenCount - 5:tokenCount)}  disabled={tokenCount === 5}>-</button>
        <p>{tokenCount}</p>
        <button className='btn' onClick={() => updateTokenCount(tokenCount + 5)}>+</button>
      </div>
      <div className="popup-buttons">
        <button className='btn' onClick={closePopup}>Close</button>
        <button className='btn btn-orange'><Link to={`/checkout?tokens=${tokenCount}`} className='next-link'>Next</Link></button>
      </div>
      </div>
    </div>
  );
};

export default TokenPopup;
