import React, {useState} from 'react'
import './ChangePrices.css';
import { useDispatch, useSelector } from 'react-redux';
import { changePrice } from '../../actions/adminAction';
const ChangePrices = ({handleClick}) => {
  const [inputValue, setInputValue] = useState('');
  const {price} = useSelector((state) => state.stats);
  const dispatch = useDispatch();
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const updatePrice = (e) => {
      e.preventDefault();
      const data = {
        priceId: price._id,
        priceValue: inputValue
      }
      dispatch(changePrice(data)).then(handleClick());
      
  }

  return (
    <div className='popup'>
      <h1>Change price</h1>
      <div className="changePrice">
        <p>New Price</p>
        <div>
        <p>₹</p>
        <input
        type="text"
        value={inputValue}
        onChange={(event) => handleInputChange(event)}
      />
        </div>
      </div>
      <button className="btn btn-orange" onClick={(e)=>updatePrice(e)}>Change Price</button>
    </div>
  ) 
}

export default ChangePrices