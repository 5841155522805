import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './SearchResult.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Sharelink from './Sharelink';
import { savePost } from '../../actions/postAction';
import { toast } from 'react-toastify';

const SearchResults = ({post}) => {
    const {postids} = useSelector((state) => state.savedposts);
    const {user} = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [saved, setsaved] = useState(postids.includes(post._id));
    const [showsharelink, setshowsharelink] = useState(false);

    const saveunsave = async(e) => {
      e.preventDefault();
      setsaved(!saved);
      const element = e.target;
      const classToToggle = 'post-saved';
      if (element.classList.contains(classToToggle)) {
        element.classList.remove(classToToggle);
      } else {
        element.classList.add(classToToggle);
      }
      const data = {
        postId: post._id,
      }
      try{
      const message = await dispatch(savePost(data));
      // console.log(message);
      // toast.success(message);
      }catch(error){
        toast.error(error);
      }
    }
    const statusTooltip = (
      <Tooltip id="status-tooltip">{(post.status == "released")?"Released":(post.status == "active")?"Active":"Standby"}</Tooltip>
    );
    const createdTooltip = (
      <Tooltip id="created-tooltip">Created on</Tooltip>
    );
    const formattedDate = moment(post.date).format('DD-MM-yyyy');
    const shareLink = (e) => {
      e.preventDefault();
      setshowsharelink(!showsharelink);
    }
    return (
      <div className="note ">
        <div >
          {post.updates.length>0?(
             <p className="update-indicator">{post.updates.length>10?"10+":post.updates.length + " updates"}</p>
          ):(<></>)}
         
        </div>
        <OverlayTrigger placement="top" overlay={statusTooltip}>
      <div className={` ${post.status==="released" ? "status-released" :post.status==="active"? "status-active":"status-standby"}`}></div>
      </OverlayTrigger>
        <Link to={`/dashboard/note-details/${post._id}`} className='note-link'>
        <div className="description">
        <p id="text" className='content'>{post.note.substring(0, 150) + '...'}</p>
        </div>
        </Link>
        <div className="note-bottom">
        <OverlayTrigger placement="top" overlay={createdTooltip}>
        <p className='create-date'>{formattedDate}</p>
        </OverlayTrigger>
        {user && (
          <i 
          className={`bx save-icon ${saved ? 'post-saved bxs-bookmark' : 'bx-bookmark'}`}
          onClick={(e)=>saveunsave(e)}
          >
          </i>
          )
          }
        </div>
        {showsharelink &&(
        <div className="overlay">
            <Sharelink setshowsharelink={setshowsharelink}  postId={post._id}/>
        </div>
      )}
      </div>
    );
}

export default SearchResults