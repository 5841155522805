import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import './Admin.css';

const Admin = () => {
    const {user} = useSelector((state) => state.user);
    const navigate= useNavigate();
    useEffect(() => {
      if(user.role!=="admin"){
        navigate("/");
      }
    }, [])
    

  return (
    <>
    <div className='admin-navbar'>
    <nav class="navbar navbar-expand-lg bg-color custom-navbar">
        <a class="navbar-brand" href="#">DMYOR ADMIN </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <Link to="/admin">
                        <p className='nav-link text-white'>Stats</p>
                    </Link>
                </li>
                <li class="nav-item">
                    <Link to="search">
                    <p class="nav-link text-white">Search</p>
                    </Link>
                </li>
                <li class="nav-item">
                    <Link to="/admin/prices">
                    <p class="nav-link text-white">Prices</p>
                    </Link>
                </li>
                
            </ul>
        </div>
        
    </nav>
    </div>
    <div className="admin-outlet">
        <Outlet/>
    </div>
</>
  )
}

export default Admin