import React from 'react';
import './Terms.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <div className="terms">
      <Helmet>
        <title>Terms of Service | Dmyor</title>
        <meta name="description" content="Explore Dmyor's terms of service to understand terms for using Dmyor." />
        <meta property="og:title" content="Dmyor Terms of Service" />
        <meta property="og:description" content="Explore Dmyor's terms of service to understand our commitment to you and your experience on our platform." />
        <meta property="og:image" content="https://res.cloudinary.com/dkgg6mdtb/image/upload/v1695966172/android-icon-144x144_ildr9u.png" />
        <meta property="og:url" content="https://www.dmyor.com/terms" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Link className='note-link' to="/">
        <p className='refund-p'><i class='bx bxs-home-alt-2'></i>Home</p>
        </Link>
        <h1>Terms of Service</h1>
       <p>
        <em>
    Last Updated: 20-12-2023
        </em>
        </p>
    <p>Welcome to Dmyor! These Terms of Service ("Terms") govern your use of our platform. By accessing or using Dmyor, you agree to these Terms. Please read them carefully.</p>

    <h2>1. Acceptance of Terms</h2>
    <p>By using Dmyor, you agree to abide by these Terms. If you do not agree with any part of these Terms, please refrain from using our services.</p>

    <h2>2. Description of Services</h2>
    <p>Dmyor provides a unique platform for creating notes to your future self and sharing them with others. By utilizing our services, you acknowledge that you understand the nature of our platform.</p>

    <h2>3. User Conduct</h2>
    <p>You are responsible for your actions on Dmyor. Do not use our platform for:</p>
    <ul className='terms-ul'>
        <li>Posting content that infringes upon the rights of others or violates any applicable laws.</li>
        <li>Harassing, impersonating, or causing harm to others.</li>
        <li>Engaging in hate speech, discrimination, or abusive behavior.</li>
        <li>Creating spam or utilizing automated means to interact with the platform.</li>
    </ul>

    <h2>4. Intellectual Property</h2>
    <p>You retain ownership of the content you create and share on Dmyor. However, by posting content, you grant Dmyor a non-exclusive, worldwide, royalty-free license to use, modify, and distribute your content solely for the purpose of providing our services.</p>

    <h2>5. Credits and Purchases</h2>
    <p>Dmyor offers credits for the creation of notes. All credit purchases are final, non-refundable and can not be cancelled. You are solely responsible for managing your credits and ensuring their secure use on our platform.</p>

    <h2>6. Privacy and Data Usage</h2>
    <p>Our Privacy Policy outlines how we collect, use, and safeguard your information. By using Dmyor, you agree to the terms and practices described in our Privacy Policy.</p>

    <h2>7. Content Sharing and Community Guidelines</h2>
    <p>When you share notes on Dmyor, they may be visible to other users. It is your responsibility to respect the privacy and rights of others while engaging in content sharing. Please adhere to our community guidelines to maintain a positive and respectful environment.</p>

    <h2>8. Disclaimer of Content Accuracy</h2>
    <p>While we strive to provide reliable services, Dmyor does not guarantee the accuracy or reliability of the content shared on our platform. Users are solely responsible for the content they create, share, and rely upon.</p>

    <h2>9. Termination of Use</h2>
    <p>We reserve the right to suspend or terminate your account if you violate these Terms or engage in harmful behavior on our platform. You may also discontinue using Dmyor at any time.</p>

    <h2>10. Changes to Terms</h2>
    <p>Dmyor may modify or update these Terms periodically. We will notify you of significant changes via email or by posting a notice on our website.</p>

    <h2>11. Contact Information</h2>
    <p>If you have any questions, concerns, or issues regarding these Terms or our services, please reach out to us at <a href="mailto:support@dmyor.com">support@dmyor.com</a>.</p>

    <p>By using Dmyor, you acknowledge that you have read, understood, and agreed to these Terms. If you do not agree with any aspect of these Terms, please refrain from using our services.</p>

    </div>
  )
}

export default Terms