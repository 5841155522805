import { toast } from "react-toastify";
import {
    CREATE_POST_REQUEST,
    CREATE_POST_SUCCESS,
    CREATE_POST_FAIL,
    CLEAR_ERRORS,
    ALL_POSTS_REQUEST,
    ALL_POSTS_SUCCESS,
    ALL_POSTS_FAIL,
    CHANGE_STATUS_REQUEST,
    CHANGE_STATUS_SUCCESS,
    CHANGE_STATUS_FAIL,
    SAVED_POSTS_REQUEST,
    SAVED_POSTS_SUCCESS,
    SAVED_POSTS_FAIL
} from "../constants/postConstants";
import {
    SEARCH_POST_REQUEST,
    SEARCH_POST_FAIL,
    SEARCH_POST_SUCCESS,
} from "../constants/searchConstants";
import axios from "axios";
import config from "../config";

const axiosconfig = {withCredentials:true};

const serverurl = config.apiUrl;
const serverConfig = {
    withCredentials:true,
}
//get all posts
export const getAllPosts = (currentPage = 1) => async(dispatch) => {
    try{
        dispatch({type: ALL_POSTS_REQUEST});
        // console.log(serverurl);
        const response = await axios.get(`${serverurl}/api/v1/mynotes?page=${currentPage}`, serverConfig);
        const {data:{postToSend, postCount}} = response;
        dispatch({type: ALL_POSTS_SUCCESS, payload: {postToSend, postCount}});
        return response
    }catch(error){
        dispatch({type: ALL_POSTS_FAIL, payload:error.response.data.message});
        toast.error(error.statusText);
        return error.response;
    }
}

//Save post
export const savePost = (postData) => async(dispatch) => {
    try{
        const response = await axios.post(`${serverurl}/api/v1/save-post`, postData,serverConfig);
        const {data:{savedPosts}} = response;
        const savedpostarray = savedPosts.map((savedPost) => savedPost.post);
        dispatch({type: SAVED_POSTS_SUCCESS, payload: savedpostarray});
        return response.data.message;
    }catch(error){
        toast.error("Something went wrong. Try again");
    }
}

//get Saved posts
export const getSavedPosts = () => async(dispatch) => {
    try{
        dispatch({type: SAVED_POSTS_REQUEST});
        const response = await axios.get(`${serverurl}/api/v1/saved`,serverConfig);
        const {data:{savedPosts}} = response;
        const savedpostarray = savedPosts.map((savedPost) => savedPost.post);
        dispatch({type: SAVED_POSTS_SUCCESS, payload: savedpostarray});
    }catch(error){
        dispatch({type: SAVED_POSTS_FAIL});
        toast.error("Something went wrong. Try again");
    }
}

//create post
export const createPost = (postData) => async(dispatch) => {
    try{
        dispatch({type: CREATE_POST_REQUEST});
        // console.log("sending post request");
        // console.log(postData);
        const response = await axios.post(
            `${serverurl}/api/v1/createpost`,
            postData,
            serverConfig
        );
        const {data: {post}} = response;
        dispatch({type: CREATE_POST_SUCCESS, payload: post});
        return post;
    } catch(error){
        dispatch({type: CREATE_POST_FAIL, payload: error.response.data.message});
        toast.error("Something went wrong. Try again");
    }
  };

  //addupdate\
  export const addUpdate = (data, setpost) => async(dispatch) => {
    try{
        const {data:{post}} = await axios.post(`${serverurl}/api/v1/addupdate`, data, serverConfig);
        setpost(post);
        // console.log(post);
    }catch(error){
        toast.error("Something went wrong. Try again");
    }
  }

  //delete post
  export const deletePost = (data) => async(dispatch) => {
    try{
    const response = await axios.post(`${serverurl}/api/v1/delete`, data, serverConfig);
    return response.data;
    }catch(error){
        return error.response.data;
    }
  }

  //Searchpost
  export const searchPost = (searchTerm, currentPage) => async (dispatch) => {
    try{
        dispatch({type: SEARCH_POST_REQUEST});
        const link = `${serverurl}/api/v1/search?keyword=${searchTerm}&page=${currentPage}`;
        const {data} = await axios.get(link);
        dispatch({type: SEARCH_POST_SUCCESS, payload: data});
        return data;
    }catch(error){
        dispatch({
            type: SEARCH_POST_FAIL,
          });
          toast.error("Something went wrong. Try again");
          return {posts:[]};
    }
  }

  //change status
  export const changeStatus = (postId) => async(dispatch) => {
    try{
        dispatch({type: CHANGE_STATUS_REQUEST});
        const response = await axios.post(
            `${serverurl}/api/v1/changestatus`,
            postId,
            serverConfig
        );
        const{data:{post}} = response;
        dispatch({type: CHANGE_STATUS_SUCCESS, payload: post});
        return response.data;
        
    }catch(error){
        dispatch({type: CHANGE_STATUS_FAIL, payload: error.response.data.message});
        toast.error("Something went wrong. Try again");
    }
  }

  export const changeVisibility = (data) => async(dispatch) => {
    try{
        const response = await axios.post(
            `${serverurl}/api/v1/changevisibility`,
            data,
            serverConfig
            );
            // console.log(response);
            return response;
    }catch(error){
        return error.response;
    }
  }

  export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };
  