import React, { useState, useEffect, useMemo} from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import './PaymentHistory.css';
import PaymentStatus from './PaymentStatus';
import { getPaymentHistory } from '../../actions/paymentAction';
import Loader from '../../layout/Loder';
import { toast } from 'react-toastify';

const PaymentHistory = () => {
  const [paymentData, setPaymentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setloading] = useState(false);
  const pageSize = 8; // Number of rows per page
  const columns = useMemo(
    () => [
        {
            Header: "Payment details",
             columns:[
                { Header: 'Id', accessor: 'orderId' },
      { Header: 'Date', accessor: 'createdAt' },
      { Header: 'Amount', accessor: 'amount' },
      { Header: 'Status', accessor: 'status', Cell:({cell:{value}}) => <PaymentStatus status={value}/> },
             ]
        }
        
      // Add more columns as needed
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data:paymentData,
  });
  useEffect(() => {
    const fetchPaymentHistory = async () => {
      try {
        setloading(true);
        const history = await getPaymentHistory();
        const modifiedData = history.map(payment => ({
          ...payment,
          amount: payment.amount / 100,
          createdAt: new Date(payment.createdAt).toLocaleDateString(), 
        }));
        
        setPaymentData(modifiedData);
      } catch (error) {
        toast.error("Some error occured")
      }
      finally{
        setloading(false);
      }
    };
  
    fetchPaymentHistory();
  }, []);

  const totalRecords = 40;
  const totalPages = Math.ceil(totalRecords / pageSize);

  // Functions to handle pagination navigation
  const goToPage = page => {
    setCurrentPage(page);
  };

  const nextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

 // ... (previous code)

return (
    <div>
      {loading?<Loader/>:(
       <>
      {paymentData.length > 0 ? (
        <table className='payment-table' {...getTableProps()} >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr className='table-header' {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                  className='table-header'
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} style={{ borderBottom: '1px solid #ddd' }}>
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '8px',
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No payments yet</div>
      )}
       </>
      )}
      {/* Pagination controls */}
    </div>
  );
  
};

export default PaymentHistory;
